import {CalendarIcon, SearchIcon} from '@primer/octicons-react';
import React, {useEffect, useState} from 'react';
import {Input, Tabs} from 'antd';
import {MyDatePicker} from '../../../../../common/NewComponents/MyDatePicker/MyDatePicker';
import {DefaultFilterMenu} from './DefaultFilterMenu';
import {ToDoList} from './ToDoList';
import Axios from './../../../../../../config/api';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {getUser} from '../../../../../../redux/slices/userSlice';
import {Block} from 'notiflix';

const FilterOptions = [
  {label: 'All', val: 'all'},
  {label: 'Created by me', val: 'by_me'},
  {label: 'Created for me', val: 'for_me'},
];

const TabContent = ({
  children,
  searchQuery,
  dateRange,
  setDateRange,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <>
      <div className="quick_note_Search_div">
        <Input
          placeholder="Search"
          prefix={<SearchIcon />}
          onChange={e => searchQuery(e.target.value)}
        />
      </div>
      <div className="row m-0 quick_note_top_filter_div pb-0">
        <div className="col-7 p-0">
          <div className="quick_note_date_picker_div">
            <CalendarIcon className="date_icon" />
            <MyDatePicker
              dateRange={dateRange}
              onChange={newRange => setDateRange(newRange)}
            />
          </div>
        </div>
        <div className="col-5 p-0 text-end">
          <DefaultFilterMenu
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            options={FilterOptions}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export const DefaultDataTabs = ({setView, setReminderId, refreshList}) => {
  const {TabPane} = Tabs;
  const USER = useSelector(getUser);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(FilterOptions[0]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [data, setData] = useState({
    unCompletedList: [],
    completedList: [],
  });

  const fetchToDoList = () => {
    Block.circle('#todoList');
    //preparing query params
    const params = new URLSearchParams({
      filterBy: selectedOption?.val,
      ...(dateRange[1]
        ? {startDate: moment(dateRange[0]).format('YYYY-MM-DD')}
        : {}),
      ...(dateRange[1]
        ? {endDate: moment(dateRange[1]).format('YYYY-MM-DD')}
        : {}),
    });
    //fetching data
    Axios.fetch(`maxproject/reminder?${params.toString()}`)
      .then(({data}) => {
        Block.remove('#todoList');
        //filtering data
        const completedList = data?.Reminders?.filter(Reminder => {
          return Reminder?.ReminderUsers?.some(
            ReminderUser =>
              ReminderUser?.user_id == USER?.id &&
              ReminderUser?.isCompleted == 1,
          );
        });

        const unCompletedList = data?.Reminders?.filter(Reminder => {
          return Reminder?.ReminderUsers?.some(
            ReminderUser =>
              ReminderUser?.user_id == USER?.id &&
              ReminderUser?.isCompleted == 0,
          );
        });
        console.log('completedList', completedList);
        console.log('unCompletedList', unCompletedList);
        //setting data
        setData({completedList, unCompletedList});
      })
      .catch(err => {
        Block.remove('#todoList');
        console.error('Error fetching the data', err);
      });
  };

  useEffect(() => {
    fetchToDoList();
  }, [dateRange, selectedOption, refreshList]);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="To do" key="1">
        <TabContent
          searchQuery={setSearchTerm}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}>
          <div className="DefaultDataTabs_div">
            <ToDoList
              setView={setView}
              searchTerm={searchTerm}
              setReminderId={setReminderId}
              isCompleted={0}
              data={data.unCompletedList}
              fetchToDoList={fetchToDoList}
            />
          </div>
        </TabContent>
      </TabPane>

      <TabPane tab="Completed" key="2">
        <TabContent
          searchQuery={setSearchTerm}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}>
          <div className="DefaultDataTabs_div">
            <ToDoList
              setView={setView}
              searchTerm={searchTerm}
              setReminderId={setReminderId}
              isCompleted={1}
              data={data.completedList}
              fetchToDoList={fetchToDoList}
            />
          </div>
        </TabContent>
      </TabPane>
    </Tabs>
  );
};
