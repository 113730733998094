import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, Drawer, TextField} from '@mui/material';
import {
  NoteIcon,
  GearIcon,
  ArchiveIcon,
  ScreenFullIcon,
  PlusCircleIcon,
  CalendarIcon,
  SearchIcon,
  XIcon,
} from '@primer/octicons-react';
import {Input} from 'antd';
import {NewBootstrapTooltip} from '../../../../common/NewComponents/NewTooltip/NewTooltip.jsx';
import {AssignToModal} from './QuickNoteList/AssignToModal.jsx';
import {QuickNoteList} from './QuickNoteList/QuickNoteList.jsx';
import {FilterMenu} from './QuickNoteList/FilterMenu.jsx';
import {MyDatePicker} from '../../../../common/NewComponents/MyDatePicker/MyDatePicker.jsx';
import {AddNotesHeader} from './AddNotes/component/AddNotesHeader.jsx';
import {AddNotesIndex} from './AddNotes/AddNotesIndex.jsx';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import Axios from './../../../../../config/api';
import {
  closeQuickNoteDrawer,
  openQuickNoteDrawer,
} from '../../../../../redux/slices/QuickNoteSlice';
import {Block, Confirm, Notify, Report} from 'notiflix';
import moment from 'moment';

const options = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Created by me',
    value: 'CREATED_BY_ME',
  },
  {
    label: 'Assigned to me',
    value: 'ASSIGNED_TO_ME',
  },
];

export const QuickNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assignToModalShow, setAssignToModalShow] = useState(false);
  const drawerState = useSelector(state => state.drawerQuickNote.isOpen);
  const [view, setView] = useState('default');
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionUsers, setSelectedCollectionUsers] = useState([]);
  const storData = useSelector(state => state);
  const [searchText, setSearchText] = useState('');
  const [notesType, setNotesType] = useState(options[0]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (drawerState && view === 'default')
      // fetch quick notes from api
      fetchNotes();
  }, [drawerState, view]);

  useEffect(() => {
    fetchNotes();
  }, [dateRange]);

  useEffect(() => {
    fetchNotes();
  }, [notesType]);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchNotes, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [searchText]);

  const fetchNotes = async () => {
    try {
      //preparing params
      let params = new URLSearchParams({
        query: searchText,
        ...(dateRange[0]
          ? {start_date: moment(dateRange[0]).format('YYYY-MM-DD')}
          : {}),
        ...(dateRange[1]
          ? {end_date: moment(dateRange[1]).format('YYYY-MM-DD')}
          : {}),
        ...(notesType.value ? {notes_type: notesType.value} : {}),
      });
      //fetching the notes from the api
      Axios.fetch(`maxproject/quick_note?${params.toString()}`)
        .then(({data}) => {
          setNotes(data?.notes);
          //setting the all users to the state
          setAllUsers(data?.users);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    } catch (error) {
      console.error('Error fetching quick notes:', error);
    }
  };

  const handleCollectionChange = collcetionId => {
    setSelectedCollection(collcetionId);
    setView('addNote');
  };

  const handleCloseDrawer = () => {
    dispatch(closeQuickNoteDrawer());
    setView('default');
    setSearchText('');
  };

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (open) dispatch(openQuickNoteDrawer());
    else handleCloseDrawer();

    // Only set loading to false after 1300ms if screen width is greater than 767px (assuming mobile threshold is 767px)
    if (window.innerWidth > 767) {
      setLoading(true);
      setTimeout(() => setLoading(false), 1300);
    } else {
      setLoading(false);
    }
  };
  const handleNavigation = path => {
    navigate(path);
    handleCloseDrawer();
  };
  const DefaultData = () => (
    <>
      <Header
        title="Quick Notes"
        ArchiveTooltip="Show Archive"
        onSettingsClick={() => handleNavigation('/Management/settings/')}
        onArchiveClick={() => setView('archive')}
      />
      <QuickNoteList
        view="default"
        onNoteClick={() => setView('addNote')}
        loading={loading}
        allNotes={notes}
        setNotes={setNotes}
        allUsers={allUsers}
        setSelectedCollection={handleCollectionChange}
      />
    </>
  );
  const ArchiveData = () => (
    <>
      <Header
        title="Archived Notes"
        ArchiveTooltip="Hide Archive"
        onSettingsClick={() => handleNavigation('/Management/settings/')}
        onArchiveClick={() => setView('default')}
      />
      <QuickNoteList
        view="archive"
        onNoteClick={() => setView('addNote')}
        loading={loading}
        allNotes={notes}
        setNotes={setNotes}
        allUsers={allUsers}
        setSelectedCollection={handleCollectionChange}
      />
    </>
  );

  const Header = ({title, onSettingsClick, onArchiveClick, ArchiveTooltip}) => (
    <>
      <div className="quick_note_header_div">
        <div className="row m-0">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 col-4 p-0 title_div">
            <h3>{title}</h3>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-8 p-0 icon_btn_div">
            <NewBootstrapTooltip
              ChildComponent={
                <span className="icon_btn" onClick={onSettingsClick}>
                  <GearIcon />
                </span>
              }
              title="Settings"
              placement="bottom"
            />
            <NewBootstrapTooltip
              ChildComponent={
                <span className="icon_btn" onClick={onArchiveClick}>
                  <ArchiveIcon />
                </span>
              }
              title={ArchiveTooltip}
              placement="bottom"
            />
            <NewBootstrapTooltip
              ChildComponent={
                <Button variant="text" onClick={() => setView('addNote')}>
                  <PlusCircleIcon />{' '}
                  <samll className="d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                    Add Note
                  </samll>
                </Button>
              }
              title="Add Note"
              placement="bottom"
            />
            <NewBootstrapTooltip
              ChildComponent={
                <span
                  className="icon_btn"
                  onClick={() =>
                    handleNavigation('/Management/meeting_notes/')
                  }>
                  <ScreenFullIcon />
                </span>
              }
              title="Full Screen"
              placement="bottom"
            />
            <NewBootstrapTooltip
              ChildComponent={
                <span className="icon_btn" onClick={toggleDrawer(false)}>
                  <XIcon />
                </span>
              }
              title="Close"
              placement="bottom"
            />
          </div>
        </div>
      </div>
      <div className="quick_note_Search_div">
        <Input
          placeholder="Search"
          prefix={<SearchIcon />}
          value={searchText}
          onChange={handleSearchTextChange}
          ref={searchInputRef}
        />
      </div>
      <div className="row m-0 quick_note_top_filter_div">
        <div className="col-7 p-0">
          <div className="quick_note_date_picker_div">
            <CalendarIcon className="date_icon" />
            <MyDatePicker dateRange={dateRange} onChange={setDateRange} />
          </div>
        </div>
        <div className="col-5 p-0 text-end">
          <FilterMenu
            options={options}
            notesType={notesType}
            setNotesType={setNotesType}
          />
        </div>
      </div>
    </>
  );

  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <div>
      <AssignToModal
        show={assignToModalShow}
        onHide={() => setAssignToModalShow(false)}
        users={selectedCollectionUsers}
        allUsers={allUsers}
        noteId={selectedCollection}
        setNotes={setNotes}
      />
      <NewBootstrapTooltip
        ChildComponent={
          <p className="top_nav_left_icon" onClick={toggleDrawer(true)}>
            <NoteIcon />
          </p>
        }
        title="Quick Note"
        placement="bottom"
      />
      <Drawer
        anchor="right"
        open={drawerState}
        onClose={toggleDrawer(false)}
        className="quick_note_one_main_div_top"
        data-theme={storData.global.isMode}>
        <div className="quick_note_one_main_div">
          {(() => {
            switch (view) {
              case 'addNote':
                return (
                  <AddNotes
                    setNotes={setNotes}
                    handleNavigation={handleNavigation}
                    setView={setView}
                    selectedCollection={selectedCollection}
                    setSelectedCollection={setSelectedCollection}
                    setAssignToModalShow={setAssignToModalShow}
                    setSelectedCollectionUsers={setSelectedCollectionUsers}
                    onNotesInnerPageClick={() =>
                      handleNavigation('/Management/meeting_notes/262')
                    }
                  />
                );
              case 'archive':
                return <ArchiveData setView={setView} />;
              default:
                return <DefaultData setView={setView} />;
            }
          })()}
        </div>
      </Drawer>
    </div>
  );
};
const AddNotes = ({
  handleNavigation,
  setView,
  setAssignToModalShow,
  onNotesInnerPageClick,
  selectedCollection,
  setSelectedCollection,
  setSelectedCollectionUsers,
  setNotes,
}) => {
  //?states
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [note_id, setNoteId] = useState(selectedCollection);
  const [collection, setCollection] = useState({});

  //?hooks
  useNonInitialEffect(() => {
    if (title.length == 0) return;
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(addUpdateNote, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [title]);

  useEffect(() => {
    if (note_id) fetchNotes();
  }, [note_id]);

  //?functions

  // Add handleTitleChange function
  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleClose = () => {
    setView('default');
    setNoteId(null);
    setTitle('');
    setColor('');
    // setCollection({});
    setSelectedCollectionUsers([]);
    setSelectedCollection(null);
  };

  const addUpdateNote = () => {
    //initialize network request
    Axios.put('maxproject/quick_note/add_update_collection', {
      title,
      color,
      note_id,
    })
      .then(({data}) => {
        if (!note_id) setNoteId(data.note.id);
      })
      .catch(err => {
        Notify.failure(err?.response?.data?.message);
      });
  };

  const fetchNotes = () => {
    //showing loader
    Block.circle('.addNotesIndex', 'Loading...');
    //initialize network request
    Axios.fetch(`maxproject/quick_note/collection/${note_id}`)
      .then(({data}) => {
        //removing loader
        Block.remove('.addNotesIndex');
        setCollection(data?.collection);
        setTitle(data?.collection?.title);
        setColor(data?.collection?.MeetingNoteSettings?.[0]?.color);
        setSelectedCollectionUsers(data?.collection?.MeetingNoteParticipants);
      })
      .catch(err => {
        //removing loader
        Block.remove('.addNotesIndex');
        Notify.failure(err?.response?.data?.message);
      });
  };

  const handleColorChange = newColor => {
    //initializing network request
    Axios.put('maxproject/quick_note/add_update_collection', {
      color: newColor,
      note_id,
    })
      .then(({data}) => {
        setColor(newColor);
        //updating the color in the state
        setNotes(old =>
          old.map(note =>
            note.id === note_id
              ? {
                  ...note,
                  MeetingNoteSettings: [
                    {
                      ...note.MeetingNoteSettings?.[0],
                      color: newColor,
                    },
                  ],
                }
              : note,
          ),
        );
      })
      .catch(err => {
        Notify.failure(err?.response?.data?.message);
      });
  };

  const handleArchiveUnarchiveNote = isArchived => {
    try {
      //initialize network request
      Axios.post(`maxproject/quick_note/archive_unarchive/${note_id}`, {
        is_archived: isArchived,
      }).then(({data}) => {
        //updating the note
        setNotes(old => {
          return old.map(note => {
            if (note.id === note_id) {
              return {
                ...note,
                MeetingNoteSettings: [
                  {
                    ...note.MeetingNoteSettings?.[0],
                    is_archived: isArchived,
                    is_pinned: false,
                  },
                ],
              };
            }
            return note;
          });
        });
        //showing the toast
        Notify.success('Note archived successfully');
        //closing the drawer
        handleClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = () => {
    if (collection?.MeetingNoteParticipants?.length)
      return Report.info(
        'Attention',
        `Please remove assigners first`,
        'Okay',
        () => {
          setAssignToModalShow(true);
        },
        {
          backOverlayColor: 'rgb(0 0 0 / 51%)',
        },
      );
    const processDeleteNote = async () => {
      try {
        //initialize network request
        Axios.delete(`maxproject/meeting_note/${note_id}`).then(({data}) => {
          //showing success message
          Notify.success(data?.message);
          //removing note
          setNotes(old => old.filter(note => note.id !== note_id));
          //closing the drawer
          handleClose();
        });
      } catch (error) {
        console.log(error);
      }
    };
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      processDeleteNote,
      () => {},
    );
  };

  return (
    <div
      className="addNotesIndex"
      style={{backgroundColor: color || 'transparent', height: '100vh'}}>
      <AddNotesHeader
        onSettingsClick={() => handleNavigation('/Management/settings/')}
        onBackClick={handleClose}
        AssignTo={() => setAssignToModalShow(true)}
        onNotesInnerPageClick={onNotesInnerPageClick}
        handleColorChange={handleColorChange}
        handleReset={() => handleColorChange('white')}
        handleArchiveUnarchiveNote={handleArchiveUnarchiveNote}
        handleDelete={handleDelete}
      />
      <div className="addNotes_title_inputDiv">
        <TextField
          id="standard-multiline-flexible"
          multiline
          variant="standard"
          placeholder="Write title here"
          onChange={handleTitleChange} // Attach the handler
          value={title} // Bind the value to the state
        />
      </div>
      <AddNotesIndex
        AssignTo={() => setAssignToModalShow(true)}
        collectionId={note_id}
        notes={collection?.RichNotes}
      />
    </div>
  );
};
