import {TextField} from '@mui/material';
import moment from 'moment';
import {AddReminderHeader} from './AddReminderHeader';
import {
  NewSelect,
  SimpleNewSelect,
} from '../../../../../common/NewComponents/NewSelect/NewSelect';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  AlertFillIcon,
  BellIcon,
  CalendarIcon,
  ChevronDownIcon,
  PersonAddIcon,
} from '@primer/octicons-react';
import {
  BlueButton,
  NewOutlinedButton,
  NewOutlinedButtonWithIcon,
  TextButton,
} from '../../../../../common/NewComponents/NewButtons/Buttons';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {FilterIcon} from '@primer/octicons-react';
import CheckIcon from '@mui/icons-material/Check';
import {useSelector} from 'react-redux';
import {openAssignToModal} from '../../../../../../redux/slices/AssignToModalSlice';
import {useDispatch} from 'react-redux';
import {AssignToModalComponent} from '../../../../../common/NewComponents/AssignToModalComponent/AssignToModalComponent';
import {QuickNoteAssignUsers} from '../../Data';
import {AttachmentsView} from '../../../../../common/NewComponents/AttachmentsView/AttachmentsView';
import {FaLink} from 'react-icons/fa';
import {Input, List} from 'antd';
import {LuText} from 'react-icons/lu';
import {FaRegPaste} from 'react-icons/fa6';
import {ImAttachment} from 'react-icons/im';
import {Block, Confirm, Notify} from 'notiflix';
import {Controller, useForm} from 'react-hook-form';
import Axios from './../../../../../../config/api';
import './addReminder.scss';
import FilePreview from '../../../../../common/ManagementComponents/FilePreview';
import {DocumentsAttachments} from '../../../../../common/ManagementComponents/DocumentsAttachments';
import {getApprovalFileUrl, StatusCode} from '../../../../../../constants';
import PdfIcon from '../../../../../../pics/Management/new.jpg';
import CommentsPdfIcon from '../../../../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../../../../pics/file.png';
import {UserParticipants} from '../../../../../common/ManagementComponents/Participants';

//
const reminderTime = [];
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 5) {
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 || 12; // Converts 0 -> 12 for 12 AM/PM
    const displayMinute = minute.toString().padStart(2, '0');
    reminderTime.push({
      value: `${displayHour}:${displayMinute} ${period}`,
      label: `${displayHour}:${displayMinute} ${period}`,
    });
  }
}

const reminderTypes = [
  {value: 'Project', label: 'Project'},
  {value: 'Other', label: 'Other'},
];

const reminderActivities = [
  {value: 'Task', label: 'Task'},
  {value: 'Meeting', label: 'Meeting'},
  {value: 'Approval', label: 'Approval'},
  {value: 'Notes', label: 'Notes'},
];

export const AddReminder = ({setView, reminderId = null}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    getValues,
    watch,
    reset,
  } = useForm();
  const watchType = watch('type', null);
  const watchActivityType = watch('activity_type', null);

  const [reminder, setReminder] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showDateTimeError, setShowDateTimeError] = useState(false);
  const [attachedFilesPreview, setAttachedFilesPreview] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [serverErrors, setServerErrors] = useState({});
  const [links, setLinks] = useState([]);
  const [selectValues, setSelectValues] = useState({
    projects: [],
    meetings: [],
    tasks: [],
    approvals: [],
    notes: [],
    meetings: [],
    users: [],
  });

  useEffect(async () => {
    getProjects();
    getAllUsers();
    return () => {};
  }, []);

  useEffect(() => {
    if (reminderId) fetchReminderData();
  }, [reminderId]);

  const getProjects = () => {
    Axios.fetch(`maxproject/random/get_all_projects`)
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        setSelectValues(old => ({
          ...old,
          projects,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const getAllUsers = () => {
    Axios.fetch(`maxproject/random/members`)
      .then(({data}) => {
        setSelectValues(old => ({
          ...old,
          users: data?.members?.map(User => ({
            value: User?.id,
            label: User?.name,
          })),
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const getUserActivities = () => {
    if (getValues('type')?.value === 'Project' && !getValues('project')?.value)
      return false;
    Axios.fetch(
      `maxproject/reminder/get_user_activities?project_id=${
        getValues('project')?.value
      }`,
    )
      .then(({data}) => {
        let tasks = data?.tasks?.map(el => ({
          value: el?.id,
          label: el?.title,
        }));
        let meetings = data?.meetings?.map(el => ({
          value: el?.id,
          label: el?.title,
        }));
        let approvals = data?.approvals?.map(el => ({
          value: el?.id,
          label: el?.title,
        }));
        let notes = data?.notes?.map(el => ({
          value: el?.id,
          label: el?.title,
        }));
        setSelectValues(old => ({
          ...old,
          tasks,
          meetings,
          approvals,
          notes,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    if (!time || !startDate) {
      setShowDateTimeError(true);
      return false;
    } else setShowDateTimeError(false);
    //clearing server errors
    setServerErrors({});
    //creating new formdata
    let formData = new FormData();
    // Prepare the payload
    const payload = {
      title: data.title,
      type: data.type.value,
      ...(data.meeting?.value ? {meeting_id: data.meeting?.value} : {}),
      ...(data.project?.value ? {project_id: data.project?.value} : {}),
      ...(data.task?.value ? {task_id: data.task?.value} : {}),
      ...(data.approval?.value ? {approval_id: data.approval?.value} : {}),
      ...(data.note?.value ? {note_id: data.note?.value} : {}),
      date: moment(startDate).format('YYYY-MM-DD'),
      time: moment(time.value, 'hh:mm A').format('HH:mm:ss'),
      attachments: uploadedFiles.map(file => file.file),
      links: JSON.stringify(links),
      users: JSON.stringify(reminder?.users?.map(el => el?.value)),
    };
    //inserting payload in formdata
    Object.keys(payload).forEach(k => formData.append(k, payload[k]));
    //checking if user uploaded any attachments
    if (data?.attachments?.length)
      Array.from(data?.attachments).forEach(attachment =>
        formData.append('attachments[]', attachment),
      );
    //showing up loader
    Block.circle('.AddReminder_top_div', 'Saving reminder');
    //initiating network request
    const Request = reminderId
      ? Axios.put('maxproject/reminder/' + reminderId, formData, {
          'Content-Type': 'multipart/form-data',
        })
      : Axios.post('maxproject/reminder', formData, {
          'Content-Type': 'multipart/form-data',
        });
    //handling response
    Request.then(async res => {
      Block.remove('.AddReminder_top_div');
      //showing success message
      Notify.success(res.data?.message);

      //going back to list
      setView('default');
    }).catch(err => {
      //removing loader
      Block.remove('.AddReminder_top_div');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const onAttachmentChange = e => {
    let files = e.target.files;
    if (files) {
      let previews = [];
      Array.from(files).forEach(file =>
        previews.push({
          name: file.name,
          url:
            file.type == 'image/jpeg' || file.type == 'image/png'
              ? URL.createObjectURL(file)
              : PdfIcon,
        }),
      );
      setAttachedFilesPreview(previews);
    }
  };
  const fetchReminderData = () => {
    //showing up loader
    Block.circle('.AddReminder_top_div', 'Loading...');
    const URL = `maxproject/reminder/${reminderId}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let payload = {
          title: data?.Reminder?.title,
          type: data?.Reminder?.Project
            ? {
                label: 'Project',
                value: 'Project',
              }
            : {
                label: 'Other',
                value: 'Other',
              },
          activity_type: {
            label: data?.Reminder?.Task
              ? 'Task'
              : data?.Reminder?.Meeting
              ? 'Meeting'
              : data?.Reminder?.Approval
              ? 'Approval'
              : 'Notes',
            value: data?.Reminder?.Task
              ? 'Task'
              : data?.Reminder?.Meeting
              ? 'Meeting'
              : data?.Reminder?.Approval
              ? 'Approval'
              : 'Notes',
          },
          project: {
            label: data?.Reminder?.Project?.title,
            value: data?.Reminder?.Project?.id,
          },
          task: {
            label: data?.Reminder?.Task?.title,
            value: data?.Reminder?.Task?.id,
          },
          meeting: {
            label: data?.Reminder?.Meeting?.title,
            value: data?.Reminder?.Meeting?.id,
          },
          approval: {
            label: data?.Reminder?.Approval?.title,
            value: data?.Reminder?.Approval?.id,
          },
          note: {
            label: data?.Reminder?.Note?.title,
            value: data?.Reminder?.Note?.id,
          },
        };
        if (!data?.Reminder?.Project) {
          payload.project = {label: 'Other', value: null};
          payload.task = null;
        }
        setReminder(old => ({
          ...old,
          users: data?.Reminder?.ReminderUsers?.map(U => ({
            value: U?.User?.id,
            label: U?.User?.name,
          })),
          userParticipants: data?.Reminder?.ReminderUsers.map(User => User),
        }));

        setUploadedAttachments(data?.Reminder?.ReminderAttachments);
        setLinks(data?.Reminder?.ReminderLinks);
        setStartDate(moment(data?.Reminder?.date).toDate());
        setTime({
          value: moment(data?.Reminder?.time, 'HH:mm:ss').format('hh:mm A'),
          label: moment(data?.Reminder?.time, 'HH:mm:ss').format('hh:mm A'),
        });
        reset(payload);
        Block.remove('.AddReminder_top_div');
      })
      .catch(err => {
        console.log(err);
        Block.remove('.AddReminder_top_div');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleDeleteAttachment = (i, file) => {
    Confirm.show(
      `Delete ${file.original_name}`,
      `${file.original_name} will be permanently deleted and can't be recovered.`,
      'Delete',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.delete(`maxproject/attachment/${file.id}`).then(({data}) => {
          Notify.success(data.message);
          setUploadedAttachments(old => {
            let arr = old.slice();
            arr.splice(i, 1);
            return arr;
          });
        });
      },
      () => {},
      {},
    );
  };
  const onUsersChange = data => {
    setReminder(old => ({...old, users: data}));
    // setReminder(old => ({
    //   ...old,
    //   userParticipants: data.map(el => ({User: el.value})),
    // }));
  };

  const handleOpenModal = () => {
    dispatch(openAssignToModal());
  };
  const toggleDiv = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <AssignToModalComponent
        selectedUsers={reminder?.users}
        onChange={onUsersChange}
        QuickNoteAssignUsers={selectValues?.users}
      />
      <AddReminderHeader setView={setView} />
      <form
        className="AddReminder_top_div"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row g-3 m-0">
          <div className="col-12 mt-0 p-0">
            <div className="addNotes_title_inputDiv">
              <TextField
                id="reminder-title"
                multiline
                variant="standard"
                placeholder="Reminder title goes here"
                fullWidth
                {...register('title', {
                  required: 'Please enter a title',
                  minLength: {
                    value: 3,
                    message: 'Please type at least 3 characters',
                  },
                })}
              />
              {errors.title && (
                <small className="errorMsg">
                  <span>
                    <AlertFillIcon />
                  </span>
                  {errors.title.message}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 p-0">
            <Controller
              name="type"
              control={control}
              rules={{
                required: 'Please select a type',
              }}
              render={({field}) => (
                <SimpleNewSelect
                  field={field}
                  onChange={el => {
                    field.onChange(el);
                    getUserActivities();
                  }}
                  Label="Select type*"
                  Options={reminderTypes}
                  Placeholder="Select Type"
                  ErrorMessage={errors?.type?.message}
                />
              )}
            />
          </div>
          {watchType?.value === 'Project' ? (
            <div className="col-12 p-0">
              <Controller
                name="project"
                control={control}
                rules={{
                  required: {
                    value: watchType?.value === 'Project',
                    message: 'Please select a project',
                  },
                }}
                render={({field}) => (
                  <SimpleNewSelect
                    field={field}
                    onChange={el => {
                      field.onChange(el);
                      getUserActivities();
                    }}
                    Label="Select Project*"
                    Options={selectValues?.projects}
                    Placeholder="Project"
                    ErrorMessage={errors?.project?.message}
                  />
                )}
              />
            </div>
          ) : null}
          <div className="col-12 p-0">
            <Controller
              name="activity_type"
              control={control}
              rules={{
                required: 'Please select a activity',
              }}
              render={({field}) => (
                <SimpleNewSelect
                  field={field}
                  onChange={el => field.onChange(el)}
                  Label="Select activity*"
                  Options={reminderActivities}
                  Placeholder="Activity"
                  ErrorMessage={errors?.activity_type?.message}
                />
              )}
            />
          </div>

          {watchActivityType?.value === 'Task' ? (
            <div className="col-12 p-0">
              <Controller
                name="task"
                control={control}
                rules={{
                  required: {
                    value: watchActivityType?.value === 'Task',
                    message: 'Please select a task',
                  },
                }}
                render={({field}) => (
                  <SimpleNewSelect
                    field={field}
                    onChange={el => field.onChange(el)}
                    Label="Select Task*"
                    Options={selectValues?.tasks}
                    Placeholder="Tasks"
                    ErrorMessage={errors?.task?.message}
                  />
                )}
              />
            </div>
          ) : null}
          {watchActivityType?.value === 'Meeting' ? (
            <div className="col-12 p-0">
              <Controller
                name="meeting"
                control={control}
                rules={{
                  required: {
                    value: watchActivityType?.value === 'Meeting',
                    message: 'Please select a meeting',
                  },
                }}
                render={({field}) => (
                  <SimpleNewSelect
                    field={field}
                    onChange={el => field.onChange(el)}
                    Label="Select Meeting*"
                    Options={selectValues?.meetings}
                    Placeholder="Meetings"
                    ErrorMessage={errors?.meeting?.message}
                  />
                )}
              />
            </div>
          ) : null}
          {watchActivityType?.value === 'Approval' ? (
            <div className="col-12 p-0">
              <Controller
                name="approval"
                control={control}
                rules={{
                  required: {
                    value: watchActivityType?.value === 'Approval',
                    message: 'Please select a approval',
                  },
                }}
                render={({field}) => (
                  <SimpleNewSelect
                    field={field}
                    onChange={el => field.onChange(el)}
                    Label="Select Task*"
                    Options={selectValues?.approvals}
                    Placeholder="Tasks"
                    ErrorMessage={errors?.approval?.message}
                  />
                )}
              />
            </div>
          ) : null}
          {watchActivityType?.value === 'Notes' ? (
            <div className="col-12 p-0">
              <Controller
                name="note"
                control={control}
                rules={{
                  required: {
                    value: watchActivityType?.value === 'Notes',
                    message: 'Please select a note',
                  },
                }}
                render={({field}) => (
                  <SimpleNewSelect
                    field={field}
                    onChange={el => field.onChange(el)}
                    Label="Select Note*"
                    Options={selectValues?.notes}
                    Placeholder="Notes"
                    ErrorMessage={errors?.note?.message}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col-12 p-0">
            <div className="new_select_box">
              <label className="label">Select date & time*</label>
            </div>
            <div className="AddReminder_DatePicker_top">
              <div className="AddReminder_DatePicker">
                <MyDatePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
                <NewSelect
                  data={reminderTime}
                  placeholder="Time"
                  value={time}
                  onChange={setTime}
                />
              </div>
              <div className="AddReminder_DatePicker_top_inner">
                <FilterSnoozeMenu />
                <NewOutlinedButtonWithIcon
                  title="Select user"
                  icon={<PersonAddIcon />}
                  onClick={handleOpenModal}
                />
              </div>
            </div>
            {showDateTimeError ? (
              <small className="errorMsg">
                <span>
                  <AlertFillIcon />
                </span>
                Please select a date and time
              </small>
            ) : null}
          </div>

          {/* <div className="col-12 d-flex  justify-content-start">
            <UserParticipants data={reminder?.userParticipants || []} />
          </div> */}
          <div className="col-12 p-0">
            <div className="new_select_box">
              <label className="label">Attachments</label>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <label htmlFor="attachemnt_input" className="NewShort_btn">
                  <ImAttachment />
                </label>
                <input
                  type="file"
                  style={{display: 'none'}}
                  multiple={true}
                  id="attachemnt_input"
                  hidden
                  {...register('attachments', {
                    required: false,
                    onChange: onAttachmentChange,
                  })}
                />
              </div>

              <span className="NewShort_btn" onClick={toggleDiv}>
                <FaLink />
              </span>
              <small className="error_message">
                {errors.attachments?.message || serverErrors?.attachments}
              </small>
            </div>
            <div className="doc_img_main_div">
              <div className="row g-3 mt-2">
                {attachedFilesPreview.map((val, i) => {
                  return (
                    <FilePreview
                      cols="col-4"
                      key={i}
                      name={val.name}
                      url={val.url}
                    />
                  );
                })}
              </div>
              <div className="row g-3 mt-2">
                {uploadedAttachments?.map((val, i) => {
                  return (
                    <DocumentsAttachments
                      cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                      key={i}
                      original_name={val.original_name}
                      url={getFileIcon(val, false)}
                      downloadUrl={val?.url}
                      handleDelete={() => handleDeleteAttachment(i, val)}
                    />
                  );
                })}
              </div>
            </div>
            <LinkDiv
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              links={links}
              setLinks={setLinks}
            />
          </div>
          <div className="col-12 d-flex  justify-content-end">
            <div>
              <NewOutlinedButton
                title="Cancel"
                onClick={() => setView('default')}
              />
            </div>
            <div className="ms-2">
              <Button
                type="submit"
                variant="text"
                className="BlueButtonText_btn"
                lassName="text_btn">
                <span className="text">{reminderId ? 'Update' : 'Create'}</span>
              </Button>
            </div>
            {/* <BlueButton /> */}
          </div>
        </div>
      </form>
    </>
  );
};

const MyDatePicker = ({startDate, setStartDate}) => {
  const CustomDatePickerInput = ({value, onClick}) => (
    <button onClick={onClick} className="main_btn">
      <span>
        <CalendarIcon />
      </span>
      {value || 'MM DD'} ,
    </button>
  );

  return (
    <>
      <div className="my_date_picker">
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          dateFormat="MM/dd/yyyy"
          // inline
          customInput={<CustomDatePickerInput />}
          isClearable={false}
        />
      </div>
    </>
  );
};

export const FilterSnoozeMenu = () => {
  const {
    global: {isMode},
  } = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Snooze');
  const options = ['All', 'Created by me', 'I am assigned'];

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleMenuItemClick = option => {
    setSelectedOption(option);
    handleClose();
  };

  return (
    <div>
      <Button
        className="quick_note_filter_btn_div"
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        onClick={handleClick}>
        <BellIcon />
        {selectedOption}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        data-theme={isMode}
        className="quick_note_filter_menu_div">
        {options.map(option => (
          <MenuItem
            key={option}
            selected={option === selectedOption}
            onClick={() => handleMenuItemClick(option)}>
            <ListItemIcon>
              {option === selectedOption && <CheckIcon />}
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const LinkDiv = ({
  isVisible,
  setIsVisible,
  links = [],
  setLinks = () => {},
}) => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const closeDiv = () => {
    setIsVisible(false);
  };

  const handleSave = () => {
    if (title && link) {
      setLinks([...links, {title, link}]); // Add new link to the array
      setIsVisible(false);
      setTitle(''); // Clear inputs after saving
      setLink('');
    }
  };

  const handleDelete = index =>
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      () => {
        setLinks(links.filter((_, i) => i !== index)); // Correctly filter out the link
      },
      () => {},
    );

  return (
    <div>
      <div
        className={`Showcontent ${
          isVisible ? 'Showcontent_open' : 'Showcontent_closed'
        }`}>
        {isVisible ? (
          <div className="row m-0 g-2">
            <div className="col-12 p-0">
              <div className="quick_note_Search_div p-0">
                <Input
                  placeholder="Link title"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  prefix={<LuText />}
                />
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="quick_note_Search_div p-0">
                <Input
                  placeholder="Paste link"
                  value={link}
                  onChange={e => setLink(e.target.value)}
                  prefix={<FaRegPaste />}
                />
              </div>
            </div>
            <div className="col-12 p-0 align-items-end d-flex justify-content-end">
              <div className="addReminder_linkBtn1">
                <TextButton onClick={closeDiv} title="Close" />
              </div>
              <div className="addReminder_linkBtn1">
                <TextButton onClick={handleSave} title="Save" />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* Display the saved links */}
      {links.length > 0 && (
        <div>
          <List
            dataSource={links}
            renderItem={(item, index) => (
              <List.Item
                className="saved-links"
                actions={[
                  <TextButton
                    onClick={() => handleDelete(index)} // Pass the index to the handleDelete function
                    title="Delete"
                    key="delete"
                  />,
                ]}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};
const getFileIcon = (file, crateImageObject = true) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
