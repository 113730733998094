// modalSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  assignToModalOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openAssignToModal: state => {
      state.assignToModalOpen = true;
    },
    closeAssignToModal: state => {
      state.assignToModalOpen = false;
    },
  },
});

export const {openAssignToModal, closeAssignToModal} = modalSlice.actions;
export default modalSlice.reducer;
