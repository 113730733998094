import {createSlice} from '@reduxjs/toolkit';

export const modalSearchBoxSlice = createSlice({
  name: 'modalSearchBox',
  initialState: {
    isSearchBoxOpen: false,
  },
  reducers: {
    openSearchBoxModal: state => {
      state.isSearchBoxOpen = true;
    },
    closeSearchBoxModal: state => {
      state.isSearchBoxOpen = false;
    },
  },
});

export const {openSearchBoxModal, closeSearchBoxModal} =
  modalSearchBoxSlice.actions;

export default modalSearchBoxSlice.reducer;
