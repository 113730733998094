import {createSlice} from '@reduxjs/toolkit';

const getInitialMode = () => {
  const savedMode = localStorage.getItem('theme_mode');
  if (savedMode) return savedMode;
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

const initialState = {
  isLoading: true,
  isLoggedIn: false,
  taskId: null,
  showTask: null,
  refreshTasks: null,
  isMode: getInitialMode(),
  systemMode: window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light',
  paginationCurrentPage: 1,
  recentSearches: {
    projects: [],
    tasks: [],
    clients: [],
    approvals: [],
    users: [],
    notes: [],
  },
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobal: (state, action) => ({...state, ...action.payload}),
    isLight: state => {
      localStorage.setItem('theme_mode', 'light');
      state.systemMode = 'light';
      state.isMode = 'light';
    },
    isDark: state => {
      localStorage.setItem('theme_mode', 'dark');
      state.systemMode = 'dark';
      state.isMode = 'dark';
    },
    isSystem: state => {
      localStorage.setItem('theme_mode', 'system');
      state.systemMode = 'system';
    },
    updateSystemMode: state => {
      state.isMode = window.matchMedia('(prefers-color-scheme: dark)')
        .matches
        ? 'dark'
        : 'light';
    },
    setPaginationCurrentPage: (state, action) => {
      state.paginationCurrentPage = action.payload;
    },
    setRecentSearches: (state, action) => {
      state.recentSearches = action.payload;
    },
  },
});

export const {
  setGlobal,
  isDark,
  isLight,
  isSystem,
  updateSystemMode,
  setPaginationCurrentPage,
  setRecentSearches,
} = slice.actions;
export const getGlobal = state => state.global;
export default slice.reducer;
