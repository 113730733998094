import {Tabs, Badge, Skeleton} from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';
import NotificationIcon from '../../../../../pics/Management/topNavNot.svg';
import {GoDotFill} from 'react-icons/go';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {setGlobal} from '../../../../../redux/slices/globalSlice';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../../config/api';
import moment from 'moment';
import {Notify} from 'notiflix/build/notiflix-notify-aio';

const {TabPane} = Tabs;

export const TabWithBadge = ({setState, loading, refresh}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const [latestNotifications, setLatestNotifications] = useState([]);
  const [olderNotifications, setOlderNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
    return () => {};
  }, [refresh]);

  useEffect(() => {
    handleNotificationAction();
  }, [params]);

  const handleNotificationAction = () => {
    if (!params.has('action')) return;
    //switch case
    switch (params.get('action')) {
      case 'task':
        return dispatch(
          setGlobal({taskId: params.get('context_id'), showTask: true}),
        );
      case 'project':
        return navigate(
          '/Management/project_details/' + params.get('context_id'),
        );
      case 'meeting':
        return navigate('/Management/meeting/' + params.get('context_id'));
      case 'received_approval':
        return navigate(
          '/Management/received_approval/' + params.get('context_id'),
        );
      case 'sent_approval':
        return navigate(
          '/Management/sent_approval/' + params.get('context_id'),
        );
      default:
        break;
    }
  };
  const handleOnNotificationClick = (Notification, idx, setNotifications) => {
    //marking notification as read
    if (!Notification?.isRead)
      handleMarkNotificationaAsRead(Notification?.id, idx, setNotifications);
    //getting notifaction payload
    let payload = Notification?.payload;
    //handle on notification click event
    if (typeof payload == 'string') payload = JSON.parse(payload);
    //switch case
    switch (payload?.type) {
      case 'TASK':
        return dispatch(
          setGlobal({taskId: payload?.context_id, showTask: true}),
        );
      case 'PROJECT':
        return navigate('/Management/project_details/' + payload?.context_id);
      case 'MEETING':
        return navigate('/Management/meeting/' + payload?.context_id);
      case 'RECEIVED_APPROVAL':
        return navigate('/Management/received_approval/' + payload?.context_id);
      case 'SENT_APPROVAL':
        return navigate('/Management/sent_approval/' + payload?.context_id);
      default:
        break;
    }
  };

  const fetchNotifications = () => {
    Block.circle('.loading');
    Axios.fetch(`maxproject/notification`)
      .then(({data}) => {
        Block.remove('.loading');
        setLatestNotifications(data?.latestNotifications);
        setOlderNotifications(data?.olderNotifications);
        setUnreadNotifications(data?.unreadNotifications);
      })
      .catch(err => {
        Block.remove('.loading');
      });
  };
  const handleRemoveNotification = (id, idx) => {
    Block.dots('#noti_' + idx);
    Axios.delete(`maxproject/notification/${id}`)
      .then(({data}) => {
        Block.remove('#noti_' + idx);
        Notify.success(data?.message);
        setLatestNotifications(old => {
          let arr = old.slice();
          arr.splice(idx, 1);
          return arr;
        });
      })
      .catch(err => {
        Block.remove('#noti_' + idx);
      });
  };
  const handleMarkNotificationaAsRead = (id, idx, setNotifications) => {
    Axios.post(`maxproject/notification/read/${id}`)
      .then(({data}) => {
        setNotifications(old =>
          old.map((item, i) => (i === idx ? {...item, isRead: true} : item)),
        );
      })
      .catch(err => {
        Block.remove('#noti_' + idx);
      });
  };

  const renderNotifications = (Notifications, setNotifications) =>
    Notifications?.map((Notification, i) => (
      <div
        key={i}
        className="notification_main_data_div"
        onClick={() =>
          handleOnNotificationClick(Notification, i, setNotifications)
        }>
        <Skeleton active avatar paragraph={{rows: 1}} loading={loading}>
          <img src={NotificationIcon} alt="icon" />
          <h3 style={{fontWeight: Notification?.isRead ? '' : '600'}}>
            {Notification?.title} - {Notification?.body}
          </h3>
          <p style={{color: Notification?.isRead ? '' : '#3656f4'}}>
            <span style={{display: Notification?.isRead ? '' : 'flex'}}>
              <GoDotFill />
            </span>
            {moment(Notification.createdAt).fromNow()}
          </p>
        </Skeleton>
      </div>
    ));

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="All" key="1">
        <div className="notification_tab_inner">
          {latestNotifications?.length || olderNotifications?.length ? (
            <span className="all_show_page_link">See all</span>
          ) : null}
          <div
            className="notification_tab_inner_data_div"
            style={{display: latestNotifications?.length ? 'block' : 'none'}}>
            <Skeleton
              active
              paragraph={{rows: 0}}
              loading={loading}
              className="ps-3">
              <h3 className="top_title">Latest</h3>
            </Skeleton>
            {renderNotifications(latestNotifications, setLatestNotifications)}
          </div>
          <div
            className="notification_tab_inner_data_div"
            style={{display: olderNotifications?.length ? 'block' : 'none'}}>
            <Skeleton
              active
              paragraph={{rows: 0}}
              loading={loading}
              className="ps-3">
              <h3 className="top_title">Older</h3>
            </Skeleton>
            {renderNotifications(olderNotifications, setOlderNotifications)}
          </div>
        </div>
      </TabPane>
      <TabPane
        tab={
          <span>
            Unread
            <Badge count={unreadNotifications?.length} offset={[10, 0]} />
          </span>
        }
        key="2">
        <div className="notification_tab_inner">
          {unreadNotifications?.length || olderNotifications?.length ? (
            <span
              className="all_show_page_link position-relative"
              style={{
                marginLeft: '24px',
              }}>
              See all
            </span>
          ) : null}
          <div className="notification_tab_inner_data_div mt-2">
            {renderNotifications(unreadNotifications, setUnreadNotifications)}
          </div>
        </div>
      </TabPane>
      <TabPane tab="I am tagged" key="3" disabled>
        Content of Tab Pane 3
      </TabPane>
    </Tabs>
  );
};
