import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {GearIcon} from '@primer/octicons-react';
import {AiOutlineDelete} from 'react-icons/ai';
import {Confirm} from 'notiflix';

export const AddNotesMenu = ({onSettingsClick, handleDelete}) => {
  const {global} = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  
  return (
    <div>
      <span
        className="Add_note_menu_modal"
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </span>
      <Menu
        data-theme={global.isMode}
        className="timesheet_Notification_Menu_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}>
        <MenuItem
          onClick={() => {
            handleDelete();
            handleClose();
          }}>
          <span className="timesheet_menu_inner_icon delete_color">
            <AiOutlineDelete />
          </span>
          <p className="timesheet_menu_inner_text delete_color">Delete</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSettingsClick();
            handleClose();
          }}>
          <span className="timesheet_menu_inner_icon">
            <GearIcon />
          </span>
          <p className="timesheet_menu_inner_text">Settings</p>
        </MenuItem>
      </Menu>
    </div>
  );
};
