import React, {useEffect, useState} from 'react';
import {Input, Modal, Tabs} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {
  CheckCircleIcon,
  ProjectIcon,
  SearchIcon,
  TasklistIcon,
  XIcon,
  NoteIcon,
} from '@primer/octicons-react';
import {PeopleIcon, PersonAddIcon, DiamondIcon} from '@primer/octicons-react';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {NewBootstrapTooltip} from '../../../../common/NewComponents/NewTooltip/NewTooltip';
import {MyDatePicker} from '../../../../common/NewComponents/MyDatePicker/MyDatePicker';
import {useNonInitialEffect} from './../../../../../utils/hooks/non-initial-effect.tsx';
import Axios from './../../../../../config/api';
import {PeopleSearchUi} from './PeopleSearchUi';
import {CreatedByMenu} from './CreatedByMenu';
import {AssignedToMenu} from './AssignedToMenu';
import {SearchStatusUi} from './SearchStatusUi';
import {
  SearchResultAllTypeUI,
  SearchResultInner,
} from './SearchResultAllTypeUI.jsx';
import {
  closeSearchBoxModal,
  openSearchBoxModal,
} from '../../../../../redux/slices/SearchBoxSlice.js';
import moment from 'moment';
import {
  setGlobal,
  setRecentSearches,
} from '../../../../../redux/slices/globalSlice.js';

export const SearchBox = () => {
  const dispatch = useDispatch();
  const isSearchBoxOpen = useSelector(
    state => state.modalSearchBox.isSearchBoxOpen,
  );
  const storData = useSelector(state => state);

  //states
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({users: []});
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [selectedFilters, setSelectedFilters] = useState({
    assignedTo: null,
    createdBy: null,
    status: null,
  });

  const [searchData, setSearchData] = useState({
    all: [],
    projects: [],
    tasks: [],
    clients: [],
    approvals: [],
    notes: [],
    users: [],
  });

  useEffect(() => {
    //getting filters data
    fetchQuickFilters();
  }, []);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchSearchResults, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  useEffect(() => {
    fetchSearchResults();
  }, [dateRange, selectedFilters]);

  const fetchQuickFilters = () => {
    //initializing network request
    Axios.fetch('maxproject/search/filters')
      .then(({data}) => {
        //updating filters
        setFilters({
          users: data?.Users?.map(User => ({...User, checked: false})),
        });
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchSearchResults = () => {
    if (!query.length)
      return setSearchData({
        all: [],
        projects: [],
        tasks: [],
        clients: [],
        approvals: [],
        users: [],
        notes: [],
      });
    //showing up loader
    setLoading(true);
    //initializing query params
    const q = new URLSearchParams({
      query,
      ...(dateRange[0]
        ? {start_date: moment(dateRange[0]).format('YYYY-MM-DD')}
        : {}),
      ...(dateRange[1]
        ? {end_date: moment(dateRange[1]).format('YYYY-MM-DD')}
        : {}),
      ...(selectedFilters.assignedTo
        ? {assigned_to: selectedFilters.assignedTo.id}
        : {}),
      ...(selectedFilters.createdBy
        ? {created_by: selectedFilters.createdBy.id}
        : {}),
      ...(selectedFilters.status ? {status: selectedFilters.status} : {}),
      limit: 10,
    });
    //initializing network request
    Axios.fetch(`maxproject/search?${q.toString()}`)
      .then(({data}) => {
        //updating search data
        setSearchData({
          projects: data?.Projects.map(Project => ({
            title: Project?.name,
            icon: <ProjectIcon />,
            link: `/Management/project_details/${Project.id}`,
            onClick: handleSearchClick('project', Project),
          })),
          tasks: data?.Tasks.map(Task => ({
            title: Task?.name,
            icon: <TasklistIcon />,
            link: `/Management/all_notifications?action=task&context_id=${Task.id}`,
            onClick: handleSearchClick('task', Task),
          })),
          users: data?.Users,
          approvals: data?.Approvals.map(Approval => ({
            title: Approval?.title,
            icon: <CheckCircleIcon />,
            link: `/Management/${
              Approval.type == 'sent' ? 'sent' : 'received'
            }_approval/${Approval.id}`,
            onClick: handleSearchClick('approval', Approval),
          })),
          notes: data?.Notes.map(Note => ({
            title: Note?.title,
            icon: <NoteIcon />,
            link: `/Management/meeting_notes/${Note?.id}`,
            onClick: handleSearchClick('note', Note),
          })),
        });
        setLoading(false);
      })
      .catch(err => {
        //removing loader
        setLoading(false);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleSearchClick = (type, data) => () => {
    const typeMap = {
      project: {
        list: 'projects',
        titleKey: 'name',
        link: `/Management/project_details/${data.id}`,
        icon: <ProjectIcon />,
      },
      task: {
        list: 'tasks',
        titleKey: 'name',
        link: `/Management/all_notifications?action=task&context_id=${data.id}`,
        icon: <TasklistIcon />,
      },
      approval: {
        list: 'approvals',
        titleKey: 'title',
        icon: <CheckCircleIcon />,
        link: `/Management/${
          data.type === 'sent' ? 'sent' : 'received'
        }_approval/${data.id}`,
      },
      note: {
        list: 'notes',
        titleKey: 'title',
        link: `/Management/meeting_notes/${data.id}`,
        icon: <NoteIcon />,
      },
    };

    const {list, titleKey, link, icon} = typeMap[type] || {};
    if (!list) return;

    // Retrieve the current items from storage
    const items = storData.global.recentSearches?.[list] || [];

    // Check if the item already exists
    if (!items.some(item => item.title === data?.[titleKey])) {
      // Add new item to the list
      const updatedItems = [
        {title: data?.[titleKey], link, icon},
        ...items.slice(0, 5),
      ];

      // Update the recent searches in global state
      dispatch(
        setRecentSearches({
          ...storData.global.recentSearches,
          [list]: updatedItems,
        }),
      );
    }
  };
  const openSearchModal = () => {
    return Notify.info(
      'Search is disabled for now, but will be available soon!',
    );
    dispatch(openSearchBoxModal());
  };

  return (
    <div>
      <NewBootstrapTooltip
        ChildComponent={
          <p className="top_nav_left_icon" onClick={openSearchModal}>
            <SearchIcon />
          </p>
        }
        title="Search"
        placement="bottom"
      />
      <Modal
        footer={null}
        open={isSearchBoxOpen}
        onCancel={() => dispatch(closeSearchBoxModal())}
        className="top_nav_Search_modal_div"
        width={1000}
        bodyStyle={{height: 482}}
        data-theme={storData.global.isMode}>
        <div className="row m-0">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 p-0">
            <div className="input_div">
              <Input
                value={query}
                onChange={e => setQuery(e.target.value)}
                name="query"
                placeholder="Search everything..."
              />
            </div>
            <div className="top_nav_search_modal_tabs">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: 'All',
                    children: (
                      <>
                        <div className="top_nav_search_all_scroll">
                          <div className="top_nav_search_all_inner_scroll">
                            {query ? (
                              <>
                                <SearchResultInner
                                  title="Projects"
                                  loading={loading}
                                  data={searchData.projects}
                                />
                                <SearchResultAllTypeUI
                                  title="Tasks"
                                  loading={loading}
                                  data={searchData.tasks}
                                />
                                <SearchResultAllTypeUI
                                  title="Approvals"
                                  loading={loading}
                                  data={searchData.approvals}
                                />
                                <SearchResultAllTypeUI
                                  title="Notes"
                                  loading={loading}
                                  data={searchData.notes}
                                />
                              </>
                            ) : (
                              <SearchResultInner
                                showClearAll
                                title="Recently Viewed"
                                loading={loading}
                                handleClearRecent={() => {
                                  dispatch(
                                    setRecentSearches({
                                      ...storData.global.recentSearches,
                                      projects: [],
                                      tasks: [],
                                      approvals: [],
                                      notes: [],
                                    }),
                                  );
                                }}
                                data={[
                                  ...storData.global.recentSearches.projects,
                                  ...storData.global.recentSearches.tasks,
                                  ...storData.global.recentSearches.approvals,
                                  ...storData.global.recentSearches.notes,
                                ]}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: '2',
                    label: 'Projects',
                    children: (
                      <SearchResultAllTypeUI
                        loading={loading}
                        title={query ? null : 'Recently Viewed'}
                        handleClearRecent={() => {
                          dispatch(
                            setRecentSearches({
                              ...storData.global.recentSearches,
                              projects: [],
                            }),
                          );
                        }}
                        data={
                          query
                            ? searchData.projects
                            : storData.global.recentSearches.projects
                        }
                      />
                    ),
                  },
                  {
                    key: '3',
                    label: 'tasks',
                    children: (
                      <SearchResultAllTypeUI
                        loading={loading}
                        title={query ? null : 'Recently Viewed'}
                        handleClearRecent={() => {
                          dispatch(
                            setRecentSearches({
                              ...storData.global.recentSearches,
                              tasks: [],
                            }),
                          );
                        }}
                        data={
                          query
                            ? searchData.tasks
                            : storData.global.recentSearches.tasks
                        }
                      />
                    ),
                  },
                  {
                    key: '4',
                    label: 'approvals',
                    children: (
                      <SearchResultAllTypeUI
                        loading={loading}
                        title={query ? null : 'Recently Viewed'}
                        handleClearRecent={() => {
                          dispatch(
                            setRecentSearches({
                              ...storData.global.recentSearches,
                              approvals: [],
                            }),
                          );
                        }}
                        data={
                          query
                            ? searchData.approvals
                            : storData.global.recentSearches.approvals
                        }
                      />
                    ),
                  },
                  {
                    key: '5',
                    label: 'users',
                    children: <PeopleSearchUi data={searchData.users} />,
                  },
                  {
                    key: '6',
                    label: 'notes',
                    children: (
                      <SearchResultAllTypeUI
                        loading={loading}
                        title={query ? null : 'Recently Viewed'}
                        handleClearRecent={() => {
                          dispatch(
                            setRecentSearches({
                              ...storData.global.recentSearches,
                              notes: [],
                            }),
                          );
                        }}
                        data={
                          query
                            ? searchData.notes
                            : storData.global.recentSearches.notes
                        }
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-4 top_nav_search_box_filter_data_div">
            <div
              className="close_btn_modal"
              onClick={() => dispatch(closeSearchBoxModal())}>
              <XIcon />
            </div>
            <div className="top_nav_search_box_filter_title_div">
              <h3>Date Filter</h3>
              <MyDatePicker dateRange={dateRange} onChange={setDateRange} />
            </div>
            <div className="top_nav_dropdown_filter_title_div">
              <h3>Quick filters</h3>
              <div className="dropdown_outer_div">
                {[
                  {
                    icon: <PeopleIcon />,
                    label: 'Assigned to',
                    component: (
                      <AssignedToMenu
                        data={filters?.users}
                        selectedUser={selectedFilters.assignedTo}
                        cb={user =>
                          setSelectedFilters({
                            ...selectedFilters,
                            assignedTo: user,
                          })
                        }
                      />
                    ),
                  },
                  {
                    icon: <PersonAddIcon />,
                    label: 'Created by',
                    component: (
                      <CreatedByMenu
                        data={filters?.users}
                        selectedUser={selectedFilters.createdBy}
                        cb={user =>
                          setSelectedFilters({
                            ...selectedFilters,
                            createdBy: user,
                          })
                        }
                      />
                    ),
                  },
                  {
                    icon: <DiamondIcon />,
                    label: 'Status',
                    component: (
                      <SearchStatusUi
                        selectedStatus={selectedFilters.status}
                        cb={status =>
                          setSelectedFilters({
                            ...selectedFilters,
                            status,
                          })
                        }
                      />
                    ),
                  },
                ].map((filter, index) => (
                  <div
                    className="row"
                    key={index}
                    style={{marginBottom: '16px'}}>
                    <div className="col-6">
                      <div className="dropdown_inner_div">
                        <span>{filter.icon}</span>
                        {filter.label}
                      </div>
                    </div>
                    <div className="col-6 dropdown_inner_2_div">
                      {filter.component}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
