import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ChevronDownIcon,
  DeviceCameraVideoIcon,
  TasklistIcon,
} from '@primer/octicons-react';
import CoffeeImgIcon from '../../../../../pics/Management/coffee.svg';

export const TimesheetMenu = ({handleStartBreak}) => {
  const {
    global: {isMode},
  } = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleNavigation = path => () => {
    navigate(path);
    handleClose();
  };

  return (
    <div className="timesheet_menu_div">
      <Button
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}
        className="timesheet_menu_btn">
        Start timer <ChevronDownIcon />
      </Button>
      <Menu
        className="timesheet_menu_inner_div"
        data-theme={isMode}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}>
        <MenuItem onClick={handleNavigation('/Management/tasks/')}>
          <span className="timesheet_menu_inner_icon">
            <TasklistIcon />
          </span>
          <p className="timesheet_menu_inner_text">Task</p>
        </MenuItem>
        <MenuItem onClick={handleNavigation('/Management/meetings/')}>
          <span className="timesheet_menu_inner_icon">
            <DeviceCameraVideoIcon />
          </span>
          <p className="timesheet_menu_inner_text">Meeting</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStartBreak();
            handleClose();
          }}>
          <span className="timesheet_menu_inner_icon">
            <img src={CoffeeImgIcon} alt="icon" />
          </span>
          <p className="timesheet_menu_inner_text">Break</p>
        </MenuItem>
      </Menu>
    </div>
  );
};
