// AssignToModalComponent.js
import React from 'react';
import {Modal} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';

import {Avatar} from '@mui/material';
import Select from 'react-select';

import {closeAssignToModal} from '../../../../redux/slices/AssignToModalSlice';
import {FullBtn} from '../../ManagementComponents/Btn/Btn';

export const UserUi = ({userName, userImg}) => (
  <div className="assign_to_modal_ui">
    <Avatar alt={userName} src={userImg} sx={{width: 24, height: 24}} />
    <span>{userName}</span>
  </div>
);

export const AssignToModalComponent = ({
  QuickNoteAssignUsers,
  selectedUsers,
  onChange = () => {},
  showModal = null,
  setShowModal = null,
}) => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(state => state?.modal?.assignToModalOpen);

  const handleClose = () => {
    if (setShowModal) return setShowModal(false);
    dispatch(closeAssignToModal());
  };

  return (
    <Modal
      show={setShowModal ? showModal : isModalOpen}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <Select
              isMulti
              options={QuickNoteAssignUsers}
              placeholder="Select members"
              onChange={onChange}
              defaultValue={selectedUsers}
            />
          </div>
          <div className="col-12 text-center mt-5">
            <FullBtn onClick={handleClose} name="Assign" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
