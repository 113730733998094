import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import NavContext from '../../Context/NavContext';
import {
  BellIcon,
  CheckCircleIcon,
  DeviceCameraVideoIcon,
  NoteIcon,
  PlusCircleIcon,
  SearchIcon,
  SidebarCollapseIcon,
  StarFillIcon,
  TasklistIcon,
} from '@primer/octicons-react';
import {Timesheet} from './Timesheet/TimeSheet';
import {SearchBox} from './SearchBox/SearchBox';
import {UserProfile} from './UserProfile';
import {Notification} from './Notification/Notification';
import {AddLink} from './AddLink';
import {QuickNote} from './QuickNote/QuickNote';
import {DarkModeMenu} from './DarkModeMenu';
import './TopNaveBar.scss';
import {useDispatch} from 'react-redux';
import {openNotificationDrawer} from '../../../../redux/slices/NotificationSlice';
import {openSearchBoxModal} from '../../../../redux/slices/SearchBoxSlice';
import {openQuickNoteDrawer} from '../../../../redux/slices/QuickNoteSlice';
//

import {ReminderIndex} from './Reminder/ReminderIndex';
import {MdAlarm} from 'react-icons/md';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import {openReminderDrawer} from '../../../../redux/slices/ReminderSlice';
export const TopNavbar = () => {
  const navigate = useNavigate();
  const {nav, setNav} = useContext(NavContext);
  const {
    global: {isMode},
  } = useSelector(state => state);

  const handleSeePlansClick = () => {
    navigate('/');
  };

  return (
    <div data-theme={isMode} className="new_top_nav_div">
      <div className="row m-0">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-6 col-6 p-0">
          <div className="top_nav_left_div">
            <div className="top_nav_menu_icon" onClick={() => setNav(!nav)}>
              <SidebarCollapseIcon />
            </div>
            <Timesheet />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-6 col-6 p-0">
          <div className="top_nav_right_div d-none d-lg-flex d-xl-flex d-xxl-flex d-md-flex">
            <div className="btn_div">
              <button variant="outlined" onClick={handleSeePlansClick}>
                <StarFillIcon className="me-1" /> See plans
              </button>
              <div className="line_div"></div>
            </div>

            <SearchBox />
            <AddLink />
            <ReminderIndex />
            <QuickNote />
            <Notification />
            <DarkModeMenu />
            <UserProfile />
          </div>
          <div className="top_nav_right_div d-flex d-lg-none d-xl-none d-xxl-none d-md-none">
            <MobileMenu />
            <DarkModeMenu />
            <UserProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileMenu = () => {
  const minWidth = 120;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearchBoxOpen = () => {
    dispatch(openSearchBoxModal());
  };
  const handleQuickNoteOpen = () => {
    dispatch(openQuickNoteDrawer());
  };
  const handleNotificationOpen = () => {
    dispatch(openNotificationDrawer());
  };
  const handleReminderOpen = () => {
    dispatch(openReminderDrawer());
  };
  //
  const AddLinkData = [
    {
      onClick: () => navigate('/Management/tasks/'),
      icon: <TasklistIcon />,
      text: 'Task',
    },
    {
      onClick: () => navigate('/Management/meetings/'),
      icon: <DeviceCameraVideoIcon />,
      text: 'Meeting',
    },
    {
      onClick: () => navigate('/Management/approvals/'),
      icon: <CheckCircleIcon />,
      text: 'Approval',
    },
  ];
  const AddMenuData = [
    {
      onClick: handleSearchBoxOpen,
      icon: <SearchIcon />,
      text: 'Search',
    },
    {
      onClick: handleReminderOpen,
      icon: <MdAlarm />,
      text: 'Reminder',
    },
    {
      onClick: handleQuickNoteOpen,
      icon: <NoteIcon />,
      text: 'Notes',
    },
    {
      onClick: handleNotificationOpen,
      icon: <BellIcon />,
      text: 'Notification',
    },
  ];

  return (
    <div className="MobileMenuMain_div">
      <Dropdown title="More" menuStyle={{minWidth}}>
        {AddMenuData.map((val, i) => (
          <Dropdown.Item
            className="MobileMenuMainItem_div"
            onClick={val.onClick}>
            <span className="icon_div">{val.icon}</span>
            {val.text}
          </Dropdown.Item>
        ))}
        <Dropdown
          title={
            <>
              <span className="icon_div">
                <PlusCircleIcon />
              </span>
              Link
            </>
          }
          className="MobileMenuMainInner_div"
          style={{minWidth}}
          placement="leftStart">
          {AddLinkData.map((val, i) => (
            <Dropdown.Item
              key={i}
              onClick={val.onClick}
              className="MobileMenuMainItem_div">
              <span className="icon_div">{val.icon}</span>
              {val.text}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Dropdown>
    </div>
  );
};
