import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {AiOutlineDelete} from 'react-icons/ai';
import {CheckIcon, GearIcon} from '@primer/octicons-react';

import Axios from './../../../../../config/api';
import {Notify} from 'notiflix';

export const NotificationMenu = ({refreshNotifications = () => {}}) => {
  const {global} = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleMarkAllAsRead = () => {
    Axios.post('/maxproject/notification/readall')
      .then(({data}) => {
        Notify.success(data?.message);
        refreshNotifications();
        handleClose();
      })
      .catch(err => {
        Notify.error(err?.message);
      });
  };

  const deleteAllNotification = () => {
    Axios.delete('/maxproject/notification/deleteall')
      .then(({data}) => {
        Notify.success(data?.message);
        refreshNotifications();
        handleClose();
      })
      .catch(err => {
        Notify.error(err?.message);
      });
  };

  return (
    <div>
      <span
        className="close_menu_modal"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVertOutlinedIcon />
      </span>
      <Menu
        data-theme={global.isMode}
        className="timesheet_Notification_Menu_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}>
        <MenuItem onClick={handleMarkAllAsRead}>
          <span className="timesheet_menu_inner_icon">
            <CheckIcon />
          </span>
          <p className="timesheet_menu_inner_text">Mark all as read</p>
        </MenuItem>
        <MenuItem onClick={deleteAllNotification}>
          <span className="timesheet_menu_inner_icon">
            <AiOutlineDelete style={{color: '#D1242F '}} />
          </span>
          <p className="timesheet_menu_inner_text" style={{color: '#D1242F '}}>
            Clear all
          </p>
        </MenuItem>
        <MenuItem onClick={handleClick}>
          <span className="timesheet_menu_inner_icon">
            <GearIcon />
          </span>
          <p className="timesheet_menu_inner_text">Settings</p>
        </MenuItem>
      </Menu>
    </div>
  );
};
