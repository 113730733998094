import './Buttons.scss';
import {ArrowLeftIcon} from '@primer/octicons-react';
import {useNavigate} from 'react-router-dom';
import NoImagesFound from '../../../../pics/Management/noimagesfound.png';
import {Button} from '@mui/material';
import {NewBootstrapTooltip} from '../NewTooltip/NewTooltip';
// NewBackButton
export const NewBackButton = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <div className="new_all_page_back_btn">
        <span onClick={() => navigate(props.link)}>
          <ArrowLeftIcon />
        </span>

        {props.img ? (
          <img src={props.img} alt="img" />
        ) : (
          <img src={NoImagesFound} alt="img" />
        )}
        <p>{props.title}</p>
      </div>
    );
  };
  const NormalButton = () => {
    return (
      <div className="new_all_page_back_btn">
        {props.img ? (
          <img src={props.img} alt="img" />
        ) : (
          <img src={NoImagesFound} alt="img" />
        )}
        <p>{props.title}</p>
      </div>
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
NewBackButton.defaultProps = {
  title: 'add name',
};
// NewBackButton end

// NewOutlinedButtonWithIcon
export const NewOutlinedButtonWithIcon = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        className="new_btn_with_icon"
        variant="outlined"
        onClick={() => navigate(props.link)}>
        <span className="icon_div">{props.icon}</span>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  const NormalButton = () => {
    return (
      <Button
        className="new_btn_with_icon"
        variant="outlined"
        onClick={props.onClick}>
        <span className="icon_div">{props.icon}</span>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
NewOutlinedButtonWithIcon.defaultProps = {
  title: 'add title',
  icon: 'add icon',
};
// NewOutlinedButtonWithIcon
// NewOutlinedButton
export const NewOutlinedButton = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        className="new_btn_with_icon"
        variant="outlined"
        onClick={() => navigate(props.link)}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  const NormalButton = () => {
    return (
      <Button
        className="new_btn_with_icon"
        variant="outlined"
        onClick={props.onClick}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
NewOutlinedButton.defaultProps = {
  title: 'add title',
  icon: 'add icon',
};
// NewOutlinedButton
// text Button
export const TextButton = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        className="text_btn"
        variant="text"
        onClick={() => navigate(props.link)}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  const NormalButton = () => {
    return (
      <Button
        variant="text"
        className="text_btn"
        lassName="text_btn"
        onClick={props.onClick}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
TextButton.defaultProps = {
  title: 'add title',
};
// text Button end
// text BlueButton
export const BlueButton = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <Button
        className="BlueButtonText_btn"
        variant="text"
        onClick={() => navigate(props.link)}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  const NormalButton = () => {
    return (
      <Button
        variant="text"
        className="BlueButtonText_btn"
        lassName="text_btn"
        onClick={props.onClick}>
        <span className="text">{props.title}</span>
      </Button>
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
BlueButton.defaultProps = {
  title: 'add title',
};
// text BlueButton end
// icon BlueButton
export const OnlyIconButton = props => {
  const LinkButton = () => {
    const navigate = useNavigate();
    return (
      <NewBootstrapTooltip
        ChildComponent={
          <span
            variant="text"
            className={props.className + 'IconButton_div'}
            onClick={() => navigate(props.link)}
            style={{
              marginLeft: props.marginLeft,
              marginRight: props.marginRight,
            }}>
            {props.icon}
          </span>
        }
        title={props.Tooltip}
        placement={props.Tooltipplacement}
      />
    );
  };
  const NormalButton = () => {
    return (
      <NewBootstrapTooltip
        ChildComponent={
          <span
            variant="text"
            className="IconButton_div"
            onClick={props.onClick}
            style={{
              marginLeft: props.marginLeft,
              marginRight: props.marginRight,
            }}>
            {props.icon}
          </span>
        }
        title={props.Tooltip}
        placement={props.Tooltipplacement}
      />
    );
  };
  return props.link ? <LinkButton /> : <NormalButton />;
};
OnlyIconButton.defaultProps = {
  icon: 'add Icon',
};
// icon BlueButton end
