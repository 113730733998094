import React, {useState, useEffect, useRef} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {Confirm, Report} from 'notiflix';
import {NewBootstrapTooltip} from '../../../../../common/NewComponents/NewTooltip/NewTooltip';
import {UserParticipants} from '../../../../../common/ManagementComponents/Participants';
import NoteThemIcon from '../../../../../../pics/Management/noteThemIcon.png';
import Axios from './../../../../../../config/api';
import {Notify} from 'notiflix';
import {
  ArchiveIcon,
  PencilIcon,
  PersonAddIcon,
  XIcon,
} from '@primer/octicons-react';
import PaintBrushIcon from '../../../../../../pics/Management/brush.svg';
import {ColorBtn} from './ColorBtn';
import {AssignToModal} from './AssignToModal';

const HoverButton = ({onClick, tooltip, children, display}) => (
  <NewBootstrapTooltip
    ChildComponent={
      <span
        className="icon_btn_div"
        onClick={onClick}
        style={{display: display}}>
        {children}
      </span>
    }
    title={tooltip}
    placement="bottom"
  />
);

export const ListCardHoverUi = ({
  setNotes,
  handleEditClick,
  handleReset,
  handleColorChange,
  ArchiveListBtn,
  noteId,
  users,
  allUsers,
  Note,
}) => {
  const [assignToModalShow, setAssignToModalShow] = useState(false);
  const [isVisibleColorDiv, setIsVisibleColorDiv] = useState(false);
  const divRef = useRef(null);

  const handleVisibilityColorDiv = () =>
    setIsVisibleColorDiv(!isVisibleColorDiv);

  const handleClickOutside = event => {
    if (divRef.current && !divRef.current?.contains(event.target)) {
      setIsVisibleColorDiv(false);
    }
  };

  useEffect(() => {
    if (isVisibleColorDiv) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isVisibleColorDiv]);

  const handleDelete = () => {
    if (users?.length)
      return Report.info(
        'Attention',
        `Please remove assigners first`,
        'Okay',
        () => {
          setAssignToModalShow(true);
        },
        {
          backOverlayColor: 'rgb(0 0 0 / 51%)',
        },
      );
    const processDeleteNote = async () => {
      try {
        //initialize network request
        Axios.delete(`maxproject/meeting_note/${noteId}`).then(({data}) => {
          //showing success message
          Notify.success(data?.message);
          //removing note
          setNotes(old => old.filter(note => note.id !== noteId));
        });
      } catch (error) {
        console.log(error);
      }
    };
    Confirm.show(
      'Delete?',
      'This action is undonable!',
      'Delete',
      'Cancel',
      processDeleteNote,
      () => {},
    );
  };
  const handleArchiveUnarchiveNote = isArchived => {
    try {
      //initialize network request
      Axios.post(`maxproject/quick_note/archive_unarchive/${noteId}`, {
        is_archived: isArchived,
      }).then(({data}) => {
        //updating the note
        setNotes(old => {
          return old.map(note => {
            if (note.id === noteId) {
              return {
                ...note,
                MeetingNoteSettings: [
                  {
                    ...note.MeetingNoteSettings?.[0],
                    is_archived: isArchived,
                    is_pinned: false,
                  },
                ],
              };
            }
            return note;
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <AssignToModal
        show={assignToModalShow}
        onHide={() => setAssignToModalShow(false)}
        users={users}
        allUsers={allUsers}
        noteId={noteId}
        setNotes={setNotes}
      />
      <div className="list_card_inner_div">
        <div className="box_ui">
          <HoverButton onClick={handleEditClick} tooltip="Edit">
            <PencilIcon className="svg1" />
          </HoverButton>
          <HoverButton
            display={ArchiveListBtn}
            onClick={() => setAssignToModalShow(true)}
            tooltip="Assigned to">
            <PersonAddIcon className="svg1" />
          </HoverButton>
          <div
            className="Participants_main_div_inner me-3"
            style={{display: ArchiveListBtn}}>
            <UserParticipants data={users} />
          </div>
          <HoverButton onClick={handleVisibilityColorDiv} tooltip="Theme">
            <img src={NoteThemIcon} alt="icon" />
          </HoverButton>
          <HoverButton onClick={handleDelete} tooltip="Delete">
            <AiOutlineDelete className="svg2" style={{color: '#D1242F'}} />
          </HoverButton>
          <HoverButton
            tooltip={
              Note?.MeetingNoteSettings?.[0]?.is_archived
                ? 'Unarchive'
                : 'Archive'
            }
            display={ArchiveListBtn}
            onClick={() => {
              Note?.MeetingNoteSettings?.[0]?.is_archived
                ? handleArchiveUnarchiveNote(false)
                : handleArchiveUnarchiveNote(true);
            }}>
            <ArchiveIcon className="svg1" />
          </HoverButton>
        </div>
      </div>
      {isVisibleColorDiv && (
        <div ref={divRef} className="top_nav_note_visible_color_div">
          <NewBootstrapTooltip
            ChildComponent={
              <span
                className="top_nav_note_visible_color_div_btn"
                onClick={() => {
                  setIsVisibleColorDiv(false);
                }}>
                <XIcon className="close_btn" />
              </span>
            }
            title="close"
            placement="bottom"
          />
          <NewBootstrapTooltip
            ChildComponent={
              <span
                className="top_nav_note_visible_color_div_btn"
                onClick={handleReset}>
                <img className="img_1" src={PaintBrushIcon} alt="icon" />
              </span>
            }
            title="Default"
            placement="bottom"
          />
          <ColorBtn divRef={divRef} handleColorChange={handleColorChange} />
        </div>
      )}
    </>
  );
};
