import {createSlice} from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'ProfileDropdownConfig',
  initialState: {
    isProfileOpen: false,
  },

  reducers: {
    toggleProfileDropdown(state) {
      state.isProfileOpen = !state.isProfileOpen;
    },

    closeProfileDropdown(state) {
      state.isProfileOpen = false;
    },
  },
});

export const {toggleProfileDropdown, closeProfileDropdown} =
  profileSlice.actions;
export default profileSlice.reducer;
