import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {FullBtn} from '../../../../../common/ManagementComponents/Btn/Btn';
import {Avatar} from '@mui/material';
import Select from 'react-select';
import {Notify} from 'notiflix';
import Axios from './../../../../../../config/api';

export const UserUi = ({userName, userImg}) => (
  <div className="assign_to_modal_ui">
    <Avatar alt={userName} src={userImg} sx={{width: 24, height: 24}} />
    <span>{userName}</span>
  </div>
);

export const AssignToModal = props => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  //creating default options
  const defaultOptions = props?.users?.map(({User}) => ({
    value: User.id,
    label: <UserUi userName={User.name} userImg={User.avatar} />,
  }));

  const allUsers = props?.allUsers?.map(user => ({
    value: user?.id,
    label: <UserUi userName={user?.name} userImg={user?.avatar} />,
  }));

  const handleUpdateParticipants = () => {
    //initialize network request
    Axios.put('maxproject/quick_note/participants/' + props?.noteId, {
      users: selectedUsers?.map(user => user?.value),
    })
      .then(res => {
        Notify.success('Participants updated');
        props?.onHide();
        props?.setNotes(prevNotes =>
          prevNotes?.map(note =>
            note?.id === props?.noteId
              ? {
                  ...note,
                  MeetingNoteParticipants: res?.data?.MeetingNoteParticipant,
                }
              : note,
          ),
        );
      })
      .catch(err => {
        Notify.error('Failed to update participants');
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <Select
              isMulti
              options={allUsers}
              placeholder="Select members"
              onChange={val => setSelectedUsers(val)}
              defaultValue={defaultOptions}
            />
          </div>
          <div className="col-12 text-center mt-5">
            <FullBtn onClick={handleUpdateParticipants} name="Assign" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
