import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {TextButton} from '../../../../../common/NewComponents/NewButtons/Buttons';
import {useSelector} from 'react-redux';
import {PinIcon, PinSlashIcon} from '@primer/octicons-react';
import {ListCard} from './ListCard';
import Axios from '../../../../../../config/api';
import {Skeleton, Stack} from '@mui/material';

export const QuickNoteList = ({
  view,
  allNotes,
  loading,
  onNoteClick,
  allUsers,
  setNotes: setParentNotes,
  setSelectedCollection,
}) => {
  const {
    global: {isMode},
  } = useSelector(state => state);
  const [notes, setNotes] = useState([]);
  const [pinnedNotes, setPinnedNotes] = useState([]);

  // Memoize ListData to avoid unnecessary recalculations
  const ListData = useMemo(() => {
    return view === 'archive'
      ? allNotes?.filter(note => note?.MeetingNoteSettings?.[0]?.is_archived)
      : allNotes?.filter(note => !note?.MeetingNoteSettings?.[0]?.is_archived);
  }, [view, allNotes]);

  useEffect(() => {
    let notes = [];
    let pinnedNotes = [];
    ListData?.forEach(note => {
      if (note?.MeetingNoteSettings?.[0]?.is_pinned) pinnedNotes.push(note);
      else notes.push(note);
    });
    setPinnedNotes(pinnedNotes);
    setNotes(notes);
  }, [ListData]);

  const handleOnDragEnd = useCallback(
    result => {
      console.log(result);
      if (!result.destination) return;
      const updatedNotes = Array.from(notes);
      const [reorderedNote] = updatedNotes.splice(result.source.index, 1);
      updatedNotes.splice(result.destination.index, 0, reorderedNote);
      setNotes(updatedNotes);
      setParentNotes(updatedNotes.concat(pinnedNotes));
    },
    [notes, pinnedNotes, setParentNotes],
  );

  const togglePin = useCallback(
    note => {
      const updatedNote = {
        ...note,
        MeetingNoteSettings: [
          {
            ...note?.MeetingNoteSettings?.[0],
            is_pinned: !note?.MeetingNoteSettings?.[0]?.is_pinned,
          },
        ],
      };

      let updatedNotes, updatedPinnedNotes;
      if (updatedNote?.MeetingNoteSettings?.[0]?.is_pinned) {
        updatedNotes = notes.filter(n => n.id !== note.id);
        updatedPinnedNotes = [...pinnedNotes, updatedNote];
      } else {
        updatedPinnedNotes = pinnedNotes.filter(n => n.id !== note.id);
        updatedNotes = [...notes, updatedNote];
      }

      setNotes(updatedNotes);
      setPinnedNotes(updatedPinnedNotes);
      setParentNotes([...updatedNotes, ...updatedPinnedNotes]);

      Axios.put(`maxproject/quick_note/pin_unpin/${updatedNote.id}`, {
        pinned: updatedNote?.MeetingNoteSettings?.[0]?.is_pinned,
      });
    },
    [notes, pinnedNotes, setParentNotes],
  );

  // const renderItems = useCallback(
  //   Notes => {
  //     return Notes?.map((Note, index) => (
  //       <Draggable key={Note.id} draggableId={Note.id.toString()} index={index}>
  //         {provided => (
  //           <div
  //             ref={provided.innerRef}
  //             {...provided.draggableProps}
  //             {...provided.dragHandleProps}
  //             className="draggable-item">
  //             <ListCard
  //               loading={loading}
  //               onNoteClick={onNoteClick}
  //               PinOnClick={() => togglePin(Note)}
  //               PinIcon={Note.pinned ? <PinSlashIcon /> : <PinIcon />}
  //               Note={Note}
  //               allUsers={allUsers}
  //               setNotes={setParentNotes}
  //               setSelectedCollection={setSelectedCollection}
  //             />
  //           </div>
  //         )}
  //       </Draggable>
  //     ));
  //   },
  //   [loading, onNoteClick, togglePin, allUsers, setParentNotes],
  // );
  const renderItems = useCallback(
    Notes => {
      return Notes?.map((Note, index) => (
        <div className="draggable-item">
          <ListCard
            loading={loading}
            onNoteClick={onNoteClick}
            PinOnClick={() => togglePin(Note)}
            PinIcon={Note.pinned ? <PinSlashIcon /> : <PinIcon />}
            Note={Note}
            allUsers={allUsers}
            setNotes={setParentNotes}
            setSelectedCollection={setSelectedCollection}
          />
        </div>
      ));
    },
    [loading, onNoteClick, togglePin, allUsers, setParentNotes],
  );

  if (loading)
    return (
      <div className="px-4">
        <Stack spacing={2}>
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
        </Stack>
      </div>
    );

  return (
    <div data-theme={isMode}>
      <div className="row m-0 quick_note_total_div">
        <div className="col-6 p-0 total_title">
          <h2>Total notes: {ListData?.length}</h2>
        </div>
        <div className="col-6 p-0 btn_div">
          <TextButton title="View all notes" link="/Management/meeting_notes" />
        </div>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {pinnedNotes?.length > 0 && view === 'default' ? (
                <div className="quick_note_pin_total_div">
                  <div className="quick_note_total_div">
                    <div className="total_title">
                      <h2>
                        <PinIcon /> Pinned
                      </h2>
                    </div>
                  </div>
                  {renderItems(pinnedNotes)}
                </div>
              ) : null}
              <div className="quick_note_total_div">
                <div className="total_title">
                  <h2>Other</h2>
                </div>
              </div>
              {renderItems(notes)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
