import React, {useState} from 'react';
import {Avatar} from 'antd';
import {GoGrabber} from 'react-icons/go';
import {
  ArchiveIcon,
  CheckCircleIcon,
  CheckIcon,
  XIcon,
} from '@primer/octicons-react';
import {ListCardHoverUi} from './ListCardHoverUi';
import TextArea from 'antd/es/input/TextArea';
import Axios from './../../../../../../config/api';
import moment from 'moment';
import {Notify} from 'notiflix';

const stringToColor = string => {
  let hash = 0;
  for (let i = 0; i < string?.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `#${[0, 1, 2]
    .map(i => `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2))
    .join('')}`;
};

const stringAvatar = name => ({
  style: {backgroundColor: stringToColor(name)},
  children: `${name?.split(' ')[0]?.[0]}${name?.split(' ')[1]?.[0]}`,
});

const UserIcon = ({UserIcon, UserName}) => (
  <Avatar
    size={20}
    src={UserIcon}
    {...(UserIcon ? {} : stringAvatar(UserName))}
  />
);

export const ListCard = ({
  Note,
  PinIcon,
  PinOnClick,
  allUsers,
  setNotes,
  setSelectedCollection,
}) => {
  const [text, setText] = useState(Note?.title);
  const [isEditing, setIsEditing] = useState(false);
  const [color, setColor] = useState(Note?.MeetingNoteSettings?.[0]?.color);

  const handleEditClick = () => setIsEditing(true);
  const handleInputChange = e => setText(e.target.value);
  const handleCancelEditing = () => {
    setIsEditing(false);
  };
  const handleColorChange = newColor => {
    //initializing network request
    Axios.put('maxproject/quick_note/add_update_collection', {
      color: newColor,
      note_id: Note?.id,
    })
      .then(({data}) => {
        setColor(newColor);
      })
      .catch(err => {
        Notify.failure(err?.response?.data?.message);
      });
  };

  const handleUpdateNote = () => {
    //initializing network request
    Axios.put('maxproject/quick_note/add_update_collection', {
      color,
      title: text,
      note_id: Note?.id,
    })
      .then(({data}) => {
        setIsEditing(false);
        // Notify.success('Note updated successfully');
      })
      .catch(err => {
        Notify.failure(err?.response?.data?.message);
      });
  };

  return (
    <>
      {isEditing ? (
        <div className="list_card_div">
          <ul>
            <li className="icon_div">
              <CheckCircleIcon />
            </li>
            <li style={{width: '100%'}}>
              <div className="quickNotesEditInput_div">
                <TextArea
                  type="text"
                  value={text}
                  onChange={handleInputChange}
                  autoSize
                />
                <div className="quickNotesEditInputBtn_div">
                  <div>
                    <button className="btn_1" onClick={handleCancelEditing}>
                      <XIcon />
                    </button>
                  </div>
                  <div>
                    <button className="btn_2" onClick={handleUpdateNote}>
                      <CheckIcon />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <div
          className="list_card_div color-box"
          onClick={() => setSelectedCollection(Note.id)}
          style={{backgroundColor: color || 'transparent'}}>
          <ul>
            <li className="icon_Grabber">
              <GoGrabber />
            </li>
            <li className="icon_div">
              {Note?.archived ? <ArchiveIcon /> : <CheckCircleIcon />}
            </li>
            <li>
              <p>{text?.length > 90 ? `${text?.substring(0, 90)}...` : text}</p>
              <div className="user_info">
                <UserIcon
                  UserIcon={Note?.User?.avatar}
                  UserName={Note?.User?.name}
                />
                {Note?.User?.name}, {moment(Note?.createdAt).format('ll')}
              </div>
            </li>
          </ul>
          <ListCardHoverUi
            ArchiveListBtn={true}
            handleColorChange={handleColorChange}
            handleReset={() => handleColorChange('white')}
            handleEditClick={handleEditClick}
            users={Note?.MeetingNoteParticipants}
            allUsers={allUsers}
            noteId={Note?.id}
            setNotes={setNotes}
            Note={Note}
          />
          {!Note?.MeetingNoteSettings?.[0]?.is_archived ? (
            <span
              style={{display: 'block'}}
              onClick={PinOnClick}
              className="icon_btn_pin_div">
              {PinIcon}
            </span>
          ) : null}
        </div>
      )}
    </>
  );
};
