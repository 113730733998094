import React, {useState} from 'react';
import {FaRegEye} from 'react-icons/fa';
import {IoMdClose} from 'react-icons/io';
import {Modal, ButtonToolbar} from 'rsuite';
import {MdFileDownload} from 'react-icons/md';
import {OutlineBtn} from '../../ManagementComponents/Btn/Btn';
import {useSelector} from 'react-redux';
export const AttachmentsView = ({fileUrl, fileTitle}) => {
  return (
    <>
      <FileIcon file={fileUrl} title={fileTitle} />
    </>
  );
};

const FileIcon = ({file, title}) => {
  const isDarkMode = useSelector(state => state.global.isMode);
  const [open, setOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  // Function to check if the file is an image by extension
  const isImage = file =>
    /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(file) || !file.includes('.pdf');

  // Function to check if the file is a PDF by extension
  const isPDF = file => /\.pdf$/i.test(file);

  // Function to open the modal
  const openModal = () => {
    setOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setOpen(false);
  };

  // Function to handle file download
  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName || 'download');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div className="NewAttachmentsView_inner">
      {isImage(file) && !imageError ? (
        <>
          <div className="sub_title_div">
            <div className="add_new_subtask_Uploaded_mai_div">
              <div className="item">
                <div className="add_new_subtask_Uploaded_img_div">
                  <img src={file} alt={title} />
                  <p>
                    {title.length > 10 ? title?.substr(0, 10) + '...' : title}
                  </p>
                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                    <div className="modal_img_view_btn_div">
                      <span
                        className="modal_img_view_btn me-1"
                        onClick={() => handleDownload(file, title)}>
                        <MdFileDownload />
                      </span>
                      <ButtonToolbar>
                        <span
                          className="modal_img_view_btn p-0"
                          onError={() => setImageError(true)}
                          onClick={openModal}>
                          <FaRegEye />
                        </span>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : isPDF(file) ? (
        <>
          <div className="sub_title_div">
            <div className="add_new_subtask_Uploaded_mai_div">
              <div className="item">
                <div className="add_new_subtask_Uploaded_img_div">
                  <img
                    src="https://maxcrm.projectmaxlence.com.au/static/media/pdf.0eaeb2ee882e8d24a459a64ca22b5b26.svg"
                    alt={title}
                  />
                  <p>
                    {title.length > 10 ? title?.substr(0, 10) + '...' : title}
                  </p>
                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                    <div className="modal_img_view_btn_div">
                      <span
                        className="modal_img_view_btn me-1"
                        onClick={() => handleDownload(file, title)}>
                        <MdFileDownload />
                      </span>
                      <ButtonToolbar>
                        <span
                          className="modal_img_view_btn p-0"
                          onError={() => setImageError(true)}
                          onClick={openModal}>
                          <FaRegEye />
                        </span>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : imageError ? (
        <p>Failed to load image</p>
      ) : (
        <p>Unsupported file type</p>
      )}

      <Modal
        size="md"
        open={open}
        onClose={closeModal}
        data-theme={isDarkMode}
        className="NewAttachments_view_modal">
        <Modal.Body
          className="sub_task_modul_body_div"
          style={{maxHeight: '35rem'}}>
          <p className="sub_task_modul_title_div2">{title || 'File Preview'}</p>
          <hr />
          <span className="close_icon" onClick={closeModal}>
            <IoMdClose className="sub_task_modul_top_close_btn_div" />
          </span>
          {isImage(file) ? (
            <div className="modalUi_img">
              <img src={file} alt={title} />
            </div>
          ) : isPDF(file) ? (
            <object
              data={file}
              style={{
                width: '100%',
                height: '35rem',
              }}></object>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
            <OutlineBtn onClick={closeModal} name="Cancel" />
            <span
              className="management_btn_full2 sub_task_modul_download_btn ms-2"
              onClick={() => handleDownload(file, title)}>
              Download
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
