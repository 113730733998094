import React from 'react';
import {DefaultDataHeader} from './DefaultDataHeader';
import {DefaultDataTabs} from './DefaultDataTabs';
import Axios from './../../../../../../config/api';
import { Notify } from 'notiflix';
//

export const DefaultData = ({
  setView,
  toggleDrawer,
  setReminderId,
}) => {
  const [refreshList, setRefreshList] = React.useState(false);
  const cbMarkAllComplete = () => {
    //fetching data
    Axios.put(`maxproject/reminder/mark_all_complete`).then(res => {
      Notify.success(res.data.message);
      setRefreshList(old => !old);
    });
  };
  return (
    <>
      <DefaultDataHeader
        setView={setView}
        toggleDrawer={toggleDrawer}
        cbMarkAllComplete={cbMarkAllComplete}
      />
      <div className="notification_top_nav_tab">
        <DefaultDataTabs
          setView={setView}
          setReminderId={setReminderId}
          refreshList={refreshList}
        />
      </div>
    </>
  );
};
