import moment from 'moment';

export const getMeetingTime = ({date, start_time, isAllDay}) =>
  Number(isAllDay)
    ? moment(date, 'YYYY-MM-DD').calendar(moment(), {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      })
    : // Combine date and time if it's not an all day event
      moment(`${date}T${start_time}`).calendar();
// Function to format integer as time duration
export const formatDuration = (durationInSeconds, shortTime = true) => {
  const duration = moment.duration(durationInSeconds, 'seconds');

  // Adjust calculations for 9-hour day
  const nineHourDays = Math.floor(duration.asHours() / 9);
  const hours = Math.floor(duration.asHours()) % 9;
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const seconds = duration.seconds();

  // Build an array of non-zero components
  const components = [];
  // if shortTime is true then only show small time units
  if (nineHourDays > 0)
    components.push(
      `${nineHourDays}${
        shortTime ? `d` : ` day${nineHourDays > 1 ? 's' : ''}`
      }`,
    );
  if (hours > 0)
    components.push(
      `${hours}${shortTime ? `h` : ` hr${hours > 1 ? 's' : ''}`}`,
    );
  if (minutes > 0)
    components.push(
      `${minutes}${shortTime ? `m` : ` min${minutes > 1 ? 's' : ''}`}`,
    );
  if (seconds > 0)
    components.push(
      `${seconds}${shortTime ? `s` : ` sec${seconds > 1 ? 's' : ''}`}`,
    );

  // Format the duration with labels
  const formattedDuration = components.join(`${shortTime ? ' ' : ', '}`);
  return formattedDuration || '00:00';
};

export const calDuration = (timer, totalDuration = 0) => {
  if (!timer) return 0;
  //calculating duration
  let duration = timer.status
    ? moment(new Date()).diff(timer.updatedAt, 'seconds') + totalDuration
    : totalDuration;
  //returning formatted duration
  return formatDuration(duration, true);
};
export function countWeekdays(startDate, endDate) {
  // Ensure the start date is before the end date
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  let count = 0;
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // Skip Sundays (0) and Saturdays (6)
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return count;
}
export function getQueryParam(paramName) {
  const params = new URLSearchParams(window.location.search);
  return params.get(paramName);
}
export function setQueryParam(paramName, paramValue, stateObject = {}) {
  const params = new URLSearchParams(window.location.search);
  params.set(paramName, paramValue);
  window.history.replaceState(
    stateObject,
    '',
    `${window.location.pathname}?${params.toString()}`,
  );
}
export function createActivityPayload(activityData, type, isRunning = false) {
  if (!activityData) return null;

  const idField =
    type === 'TASK' ? 'task_id' : type === 'MEETING' ? 'meeting_id' : 'id';
  const nameField =
    type === 'TASK'
      ? activityData.Task?.name
      : type === 'MEETING'
      ? activityData.Meeting?.title
      : 'Break';

  return {
    isRunning,
    duration: activityData.duration,
    id: Number(activityData[idField]),
    name: nameField,
    type,
    updatedAt: activityData.updatedAt,
  };
}
