// drawerQuickNoteReducer.js

// Action Types
const OPEN_DRAWER = 'OPEN_DRAWER';
const CLOSE_DRAWER = 'CLOSE_DRAWER';

// Action Creators
export const openQuickNoteDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeQuickNoteDrawer = () => ({
  type: CLOSE_DRAWER,
});

// Initial State
const initialState = {
  isOpen: false,
};

// Reducer
const drawerQuickNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default drawerQuickNoteReducer;
