import React, {useState} from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  CheckIcon,
  PlusCircleIcon,
  XIcon,
  GearIcon,
} from '@primer/octicons-react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {AiOutlineDelete} from 'react-icons/ai';
import {Confirm} from 'notiflix';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {closeReminderDrawer} from '../../../../../../redux/slices/ReminderSlice';
import {NewBootstrapTooltip} from '../../../../../common/NewComponents/NewTooltip/NewTooltip';

export const DefaultDataHeader = ({
  toggleDrawer,
  setView,
  cbMarkAllComplete,
}) => (
  <div className="quick_note_header_div">
    <div className="row m-0">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 col-4 p-0 title_div">
        <h3>Reminder</h3>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-8 p-0 icon_btn_div">
        <NewBootstrapTooltip
          ChildComponent={
            <Button variant="text" onClick={() => setView('addReminder')}>
              <PlusCircleIcon /> Add Reminder
            </Button>
          }
          title="Add Reminder"
          placement="bottom"
        />
        <DefaultDataMenu cbMarkAllComplete={cbMarkAllComplete} />
        <NewBootstrapTooltip
          ChildComponent={
            <span className="icon_btn" onClick={toggleDrawer(false)}>
              <XIcon />
            </span>
          }
          title="Close"
          placement="bottom"
        />
      </div>
    </div>
  </div>
);

const DefaultDataMenu = ({cbMarkAllComplete = () => {}}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {global} = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleClearAll = () =>
    Confirm.show(
      'Clear all?',
      'This action is undonable!',
      'Clear all',
      'Cancel',
      () => {},
      () => {},
    );

  const handleNavigation = path => {
    navigate(path);
    dispatch(closeReminderDrawer());
    handleClose();
  };

  const handleMarkAllComplete = () => {
    Confirm.show(
      'Mark all completed?',
      'This action is undonable!',
      'Mark all',
      'Cancel',
      () => {
        cbMarkAllComplete()
        handleClose();
      },
      () => {},
    );
  };

  return (
    <>
      <span
        className="Add_note_menu_modal"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}>
        <MoreVertOutlinedIcon />
      </span>
      <Menu
        data-theme={global.isMode}
        className="timesheet_Notification_Menu_div"
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleMarkAllComplete}>
          <span className="timesheet_menu_inner_icon">
            <CheckIcon />
          </span>

          <p className="timesheet_menu_inner_text">Mark all completed</p>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleClearAll();
            handleClose();
          }}>
          <span className="timesheet_menu_inner_icon">
            <AiOutlineDelete className="delete_color" />
          </span>

          <p className=" timesheet_menu_inner_text delete_color">Clear all</p>
        </MenuItem> */}

        <MenuItem onClick={() => handleNavigation('/Management/settings/')}>
          <span className="timesheet_menu_inner_icon">
            <GearIcon />
          </span>
          <p className="timesheet_menu_inner_text">Settings</p>
        </MenuItem>
      </Menu>
    </>
  );
};
