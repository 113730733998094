import {useState} from 'react';
import Select, {components} from 'react-select';
import {AlertFillIcon} from '@primer/octicons-react';
import {FaCaretDown} from 'react-icons/fa';
import './NewSelect.scss';
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown />
    </components.DropdownIndicator>
  );
};
export const NewSelect = props => {
  const [selected, setSelected] = useState(props?.value);
  
  const handleChange = selectedOption => {
    const [selected] = props.data?.filter(o => o?.value === selectedOption);
    setSelected(selected || props.data[0]);
    props?.onChange(selected || props.data[0]);
  };
  const getFormatedV2 = (label, userInput) => {
    // Highlight letters
    return label
      ?.split(' ')
      .map(word =>
        word
          .split('')
          .map(c =>
            userInput.toLowerCase().includes(c.toLowerCase()) ? (
              <b style={{color: '#3656F4'}}>{c}</b>
            ) : (
              c
            ),
          ),
      );
  };
  const Option = props => {
    const userInput = props?.selectProps?.inputValue || '';
    const label = props?.data?.label || '';
    return (
      <components.Option {...props}>
        <div>
          {userInput?.length
            ? label?.split(' ').length
              ? getFormatedV2(label, userInput)
              : label
            : label}
        </div>
      </components.Option>
    );
  };
  const filterOption = (option, inputValue) => {
    const {label, value} = option;
    const otherKey = props.data.filter(
      opt => opt.label === label && opt.value.includes(inputValue),
    );
    return value.includes(inputValue) || otherKey.length > 0;
  };

  return (
    <>
      <div className="new_select_box">
        <label className="label">{props.label}</label>
        <Select
          options={props.data}
          components={{DropdownIndicator, Option}}
          placeholder={props.placeholder}
          // isMulti
          // menuIsOpen
          filterOption={filterOption}
          onChange={newValue => handleChange(newValue?.value)}
          value={selected}
        />
        <small className="small">
          <span>
            <AlertFillIcon />
          </span>
          {props.ErrorMessage}
        </small>
      </div>
    </>
  );
};
export const SimpleNewSelect = ({
  Label,
  Options,
  Placeholder,
  ErrorMessage,
  onChange,
  field = {},
}) => {
  return (
    <div className="new_select_box">
      <label className="label">{Label}</label>
      <Select
        {...field}
        options={Options}
        placeholder={Placeholder}
        components={{DropdownIndicator}}
        isSearchable={false}
        onChange={onChange}
        // isMulti
        // menuIsOpen
      />
      {ErrorMessage ? (
        <small className="small">
          <span>
            <AlertFillIcon />
          </span>
          {ErrorMessage}
        </small>
      ) : null}
    </div>
  );
};
