//STYLES
import styles from './Navbar.module.scss';

//CONTEXT
import {useContext} from 'react';
import NavContext from '../../Context/NavContext';
//REACT ROUTER
import {NavLink, useNavigate} from 'react-router-dom';
import Logo from '../../../../pics/Management/max_pm.png';
import LogoImg from '../../../common/components/NavLogo/Logo';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {useState} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {useSelector} from 'react-redux';
//
import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  AppsIcon,
  ProjectIcon,
  TasklistIcon,
  PeopleIcon,
  DeviceCameraVideoIcon,
  NoteIcon,
  CommentDiscussionIcon,
  CheckCircleIcon,
  RepoTemplateIcon,
  GearIcon,
  StopwatchIcon,
} from '@primer/octicons-react';

import Axios from './../../../../config/api';

//
const NavUrl = ({
  url,
  icon,
  description,
  navItems,
  NavBadgeBg,
  badgeText,
  pointerEvents,
  disabled = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const {nav, setNav} = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  const DropdownItem = () => (
    <>
      <div
        className={styles.test}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {icon}
        <span className={styles.description}>
          {description}
          <span className="last_icon">
            {navItems ? (
              isDropdownOpen ? (
                <FiChevronRight />
              ) : (
                <FiChevronDown />
              )
            ) : null}
          </span>
        </span>
      </div>
      <div
        className={`${
          isDropdownOpen ? 'isDropdownOpen_show' : 'isDropdownOpen_hide'
        }`}>
        {navItems.map((item, i) => (
          <div className="isDropdownOpen_sub_menu" key={i}>
            <NavLink
              to={item.link}
              onClick={() => checkWindowSize()}
              className={({isActive}) =>
                isActive ? styles.active : undefined
              }>
              <span>
                {item.iconIner}
                {item.title}
              </span>
            </NavLink>
          </div>
        ))}
      </div>
    </>
  );
  const NavItem = () => (
    <div style={disabled ? {cursor: 'not-allowed'} : null}>
      <NavLink
        style={{
          pointerEvents,
          ...(disabled ? {color: 'gray', filter: 'opacity(0.5)'} : {}),
        }}
        to={url}
        className={({isActive}) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}>
        {icon}

        <span className={styles.description}>{description}</span>
        {badgeText ? (
          <p className={styles.btn_badge} style={{backgroundColor: NavBadgeBg}}>
            {badgeText}
          </p>
        ) : null}
      </NavLink>
    </div>
  );
  return (
    <>
      <li className={styles.li_navlink}>
        {navItems ? <DropdownItem /> : <NavItem />}
      </li>
    </>
  );
};
const NavIcon = props => {
  return (
    <>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip>{props.toolTipTitle}</Tooltip>}>
        <small>{props.icon}</small>
      </OverlayTrigger>
    </>
  );
};

const Navbar = ({navItems}) => {
  const {nav, setNav} = useContext(NavContext);
  const storData = useSelector(state => state);
  const [navBadges, setNavBadges] = useState({
    meeting: null,
    approval: null,
  });

  React.useEffect(() => {
    Axios.fetch(`maxproject/random/nav_badges`).then(({data}) => {
      setNavBadges(data);
    });
  }, []);

  return (
    <div
      className={`${styles.navbar_container} ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
      data-theme={storData.global.isMode}>
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo_div}>
          <div className={styles.text_div}>
            <span>pm</span>
          </div>
        </div>
        {/* MENU */}
        <div className="menu_container_new right_nav">
          <ul className={styles.menu_container}>
            <NavTopMenu />
            <hr className={styles.my_new_hr} />

            <NavUrl
              url="/Management/dashboard"
              icon={
                <NavIcon
                  icon={<AppsIcon size={16} />}
                  toolTipTitle="Projects"
                />
              }
              description="Dashboard"
            />
            <NavUrl
              url="/Management/project_list"
              icon={
                <NavIcon
                  icon={<ProjectIcon size={16} />}
                  toolTipTitle="Projects"
                />
              }
              description="Projects"
            />
            <NavUrl
              url="/Management/tasks"
              icon={
                <NavIcon
                  icon={<TasklistIcon size={16} />}
                  toolTipTitle="Tasks"
                />
              }
              description="Tasks"
            />
            <NavUrl
              url="/Management/clients"
              icon={
                <NavIcon
                  icon={<PeopleIcon size={16} />}
                  toolTipTitle="Clients"
                />
              }
              description="Clients"
            />
            <NavUrl
              NavBadgeBg="#3656f4"
              badgeText={navBadges?.meeting}
              url="/Management/meetings"
              icon={
                <NavIcon
                  icon={<DeviceCameraVideoIcon size={16} />}
                  toolTipTitle="Meetings"
                />
              }
              description="Meetings"
            />
            <NavUrl
              url="/Management/meeting_notes"
              icon={
                <NavIcon icon={<NoteIcon size={16} />} toolTipTitle="Notes" />
              }
              description="Notes"
            />
            <NavUrl
              url="/Management/chats"
              icon={
                <NavIcon
                  icon={<CommentDiscussionIcon size={16} />}
                  toolTipTitle="Chats"
                />
              }
              description="Chats"
              pointerEvents="none"
              disabled
            />

            <NavUrl
              url="/Management/approvals"
              icon={
                <NavIcon
                  icon={<CheckCircleIcon size={16} />}
                  toolTipTitle="Approvals"
                />
              }
              description="Approvals"
              NavBadgeBg={'#3656f4'}
              badgeText={navBadges?.approval}
            />
            <NavUrl
              url="/Management/reports"
              icon={
                <NavIcon
                  icon={<RepoTemplateIcon size={16} />}
                  toolTipTitle="Reports"
                />
              }
              description="Reports"
              pointerEvents="none"
              disabled
            />
            <NavUrl
              url="/Management/my_timesheet"
              icon={
                <NavIcon
                  icon={<StopwatchIcon size={16} />}
                  toolTipTitle="Settings"
                />
              }
              description="Timesheet"
            />
            <hr className={styles.my_new_hr} />
            <NavUrl
              url="/Management/settings"
              icon={
                <NavIcon
                  icon={<GearIcon size={16} />}
                  toolTipTitle="Settings"
                />
              }
              description="Settings"
            />
            {/* <NavUrl
              url="/Management/help/"
              icon={
                <NavIcon icon={<InfoIcon size={16} />} toolTipTitle="Help" />
              }
              description="Help"
            /> */}
          </ul>
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}></div>
    </div>
  );
};

const NavTopMenu = () => {
  const navigate = useNavigate();
  const {nav, setNav} = useContext(NavContext);
  const storData = useSelector(state => state);

  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    // setAnchorEl(event.currentTarget);
    navigate('/Management/dashboard');
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({theme}) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  //
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  return (
    <>
      <span className={styles.li_navlink} style={{marginTop: '8px'}}>
        <div
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          className={styles.nav_bar_drop_top_div}>
          <a href="#!" onClick={() => checkWindowSize()}>
            <p className={styles.nav_bar_drop_text}>m</p>
            <span className={styles.description}>
              Maxlence
              {/* <ChevronDownIcon /> */}
            </span>
          </a>
        </div>
      </span>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        className={styles.nav_bar_drop_inner_menu_div}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-theme={storData.global.isMode}>
        <MenuItem onClick={handleClose} disableRipple>
          <div className={styles.nav_bar_drop_inner_item_div}>
            <span className={styles.Maxlence}>m</span>

            <div>
              <p>
                Maxlence
                <br />
              </p>
              <small>18 members</small>
            </div>
          </div>
        </MenuItem>
        <hr className={styles.my_new_hr} />
        <p className={styles.menu_title_nav}>switch workspaces</p>
        <MenuItem onClick={handleClose} disableRipple>
          <div className={styles.nav_bar_drop_inner_item_div}>
            <span className={styles.Sales}>s</span>
            <div>
              <p>
                Sales
                <br />
              </p>
              <small>18 members</small>
            </div>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <div className={styles.nav_bar_drop_inner_item_div}>
            <span className={styles.hr_workspace}>h</span>
            <div>
              <p>
                HR Workspace
                <br />
              </p>
              <small>18 members</small>
            </div>
          </div>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default Navbar;
