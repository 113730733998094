import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {FilterIcon} from '@primer/octicons-react';
import CheckIcon from '@mui/icons-material/Check';

export const FilterMenu = ({setNotesType, notesType, options}) => {
  const {
    global: {isMode},
  } = useSelector(state => state);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleMenuItemClick = option => {
    setNotesType(option);
    handleClose();
  };

  return (
    <div>
      <Button
        className="quick_note_filter_btn_div"
        id="basic-button"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        onClick={handleClick}>
        {notesType.label}
        <FilterIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        data-theme={isMode}
        className="quick_note_filter_menu_div">
        {options.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === notesType}
            onClick={() => handleMenuItemClick(option)}>
            <ListItemIcon>
              {option.value === notesType && <CheckIcon />}
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
